import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import classNames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";

import LoadingSpinnerPage from "../../common/LoadingSpinnerPage";
import { PlanTier } from "../../components/graphql/schema.graphql";
import { BillingPeriod } from "../../components/graphql/schema.graphql";
import { useShowSnackbar } from "../../hooks";
import useSelectPlan from "../../hooks/useSelectPlan";
import { OfferedPlan } from "../../utils/config";
import config from "../../utils/config";
import { isError, isWorkspace } from "../../utils/graphql";
import PlanSummary from "./PlanSummary";
import PriceBanner from "./PriceBanner";
import { GetWorkspaceSettingsPlansView } from "./queries.graphql";
import SelectPlanButtons from "./SelectPlanButtons";

interface TableSection {
  name: string;
  features: {
    name: string;
    tooltip?: string;
    getTierValue?: (plan: OfferedPlan) => string | JSX.Element;
    tiers?: Partial<Record<PlanTier, boolean | string>>;
  }[];
}

interface PricingPlansProps {
  onboarding?: boolean;
  targetPlan?: PlanTier;
}

const billingPeriods = [
  {
    value: "MONTH" as BillingPeriod,
    label: "Monthly",
    priceSuffix: "/month",
  },
  {
    value: "YEAR" as BillingPeriod,
    label: "Annually",
    priceSuffix: "/year",
  },
];

const tableSections: TableSection[] = [
  {
    name: "Usage",
    features: [
      {
        name: "Generations",
        tooltip: "The number of images the plan can generate.",
        getTierValue: (plan: OfferedPlan) => {
          const limit = plan.constraints.maxMonthlyInferences;
          if (limit >= 999999) {
            return "Unlimited";
          }
          return `${limit.toLocaleString("en-US")} per month`;
        },
      },
      {
        name: "Generation Speed",
        tooltip: "How fast your image generations are.",
        tiers: {
          ["FREE"]: "Normal",
          ["PRO"]: "Faster",
          ["STUDIO"]: "Fastest",
          ["ENTERPRISE"]: "Fastest",
        },
      },
      {
        name: "Team Members",
        tooltip:
          "The maxiumum number of team members that you can invite to your workspace.",
        getTierValue: (plan: OfferedPlan) => {
          if (plan.constraints.maxMembers >= 999999) {
            return "Unlimited";
          }
          return plan.constraints.maxMembers.toLocaleString("en-US");
        },
      },
      {
        name: "Storage",
        tooltip: "Storage allowances per plan.",
        tiers: {
          ["FREE"]: "1GB storage",
          ["PRO"]: "50GB storage",
          ["STUDIO"]: "1TB + 500GB storage /seat",
          ["ENTERPRISE"]: "5TB + 1TB storage /seat",
        },
      },
    ],
  },
  {
    name: "Art Styles",
    features: [
      {
        name: "Starter Styles",
        tooltip: "How many starter styles each plan has access to.",
        tiers: {
          ["FREE"]: "40+",
          ["PRO"]: "40+",
          ["STUDIO"]: "40+",
          ["ENTERPRISE"]: "Optional",
        },
      },
      {
        name: "Custom Styles",
        tooltip:
          "Whether the plan allows for teaching AI your unique game style.",
        tiers: {
          ["PRO"]: true,
          ["STUDIO"]: true,
          ["ENTERPRISE"]: true,
        },
      },
      {
        name: "Style Training Time",
        tooltip: "The amount of time it takes on average to train a new style.",
        tiers: {
          ["PRO"]: "Normal",
          ["STUDIO"]: "Faster",
          ["ENTERPRISE"]: "Fastest",
        },
      },
      {
        name: "Max Retained Styles",
        tooltip:
          "How many custom styles you can keep for use in your workspace.",
        getTierValue: (plan: OfferedPlan) =>
          getTierValueWithSeats(
            plan.constraints.maxWorkspaceStyles,
            plan.constraints.maxWorkspaceStylesPerSeat,
          ),
      },
      {
        name: "Concurrent Style Trainings",
        tooltip: "How many styles you can generate at any one time.",
        getTierValue: (plan: OfferedPlan) =>
          getTierValueWithSeats(
            plan.constraints.maxConcurrentTrainings,
            plan.constraints.maxConcurrentTrainingsPerSeat,
          ),
      },
      {
        name: "Style Trainings Per Month",
        tooltip: "How many styles you can generate within a month.",
        getTierValue: (plan: OfferedPlan) =>
          getTierValueWithSeats(
            plan.constraints.maxMonthlyTrainings,
            plan.constraints.maxMonthlyTrainingsPerSeat,
          ),
      },
    ],
  },
  {
    name: "Admin",
    features: [
      {
        name: "Admin Content Search",
        tooltip:
          "Admin Content Search provides visability into your organization's activity, helping you ensure complicance to company policies.",
        tiers: {
          ["STUDIO"]: true,
          ["ENTERPRISE"]: true,
        },
      },
      {
        name: "SOCII Type I & 2 Compliance",
        tooltip: "SOCII Type I & 2 compliance.",
        tiers: {
          ["ENTERPRISE"]: true,
        },
      },
      {
        name: "User Provisioning (SCIM)",
        tooltip:
          "Automatically provision members in and out of your workspace with identity providers like Okta.",
        tiers: {
          ["ENTERPRISE"]: true,
        },
      },
      {
        name: "SSO (Sign In)",
        tooltip: "Manage employee access at scale with Single Sign On.",
        tiers: {
          ["ENTERPRISE"]: true,
        },
      },
      {
        name: "User Role Management",
        tooltip: "Decide user roles and permissions.",
        tiers: {
          ["ENTERPRISE"]: true,
        },
      },
    ],
  },
  {
    name: "Support",
    features: [
      {
        name: "Community",
        tooltip: "Access to community discussions and feedback.",
        tiers: {
          ["FREE"]: true,
          ["PRO"]: true,
          ["STUDIO"]: true,
          ["ENTERPRISE"]: true,
        },
      },
      {
        name: "Ticket Support",
        tooltip:
          "Ticketed support to help you resolve your questions or issues.",
        tiers: {
          ["PRO"]: true,
          ["STUDIO"]: true,
          ["ENTERPRISE"]: true,
        },
      },
      {
        name: "Onboarding Support",
        tooltip:
          "Dedicated support to help you get onboarded, create and refine your first art styles.",
        tiers: {
          ["STUDIO"]: true,
          ["ENTERPRISE"]: true,
        },
      },
      {
        name: "24/7 Support",
        tooltip: "Dedicated, ongoing, 24/7 support.",
        tiers: { ["STUDIO"]: true, ["ENTERPRISE"]: true },
      },
      {
        name: "On-demand Training",
        tooltip:
          "Dedicated workshops to help you and your team get the most out of Layer.",
        tiers: { ["ENTERPRISE"]: true },
      },
      {
        name: "First Response Time",
        tooltip: "4 hours response time to help you resolve questions faster.",
        tiers: { ["ENTERPRISE"]: "4 hours" },
      },
    ],
  },
];

export default function PricingPlans({
  onboarding,
  targetPlan,
}: PricingPlansProps) {
  const { workspace: workspaceName } = useParams();
  const [tableBillingPeriod, setTableBillingPeriod] = useState(
    billingPeriods[0],
  );
  const selectPlan = useSelectPlan();
  const { showError } = useShowSnackbar();

  const [viewQuery] = useQuery({
    query: GetWorkspaceSettingsPlansView,
    variables: {
      workspaceName: workspaceName,
    },
  });

  const resp = viewQuery.data?.workspace;
  const workspace = isWorkspace(resp) ? resp : null;

  useEffect(() => {
    if (workspace?.billingInfo?.period) {
      const periodData = billingPeriods.find(
        (p) => p.value === workspace?.billingInfo?.period,
      );
      if (periodData) {
        setTableBillingPeriod(periodData);
      }
    }
  }, [workspace?.billingInfo?.period]);

  if (isError(resp)) {
    showError(resp.message);
    return null;
  }

  if (!workspace || !workspace.billingInfo) {
    return <LoadingSpinnerPage />;
  }

  const offeredPlans = config.OFFERED_PLANS;
  const workspacePlan =
    offeredPlans.find((plan) => workspace?.planTier === plan.tier) ?? null;

  const workspacePlanIndex = offeredPlans.findIndex(
    (plan) => plan.tier === workspacePlan.tier,
  );

  const startIndex =
    workspacePlanIndex >= offeredPlans.length - 1
      ? offeredPlans.length - 3
      : workspacePlanIndex;
  const displayPlans = offeredPlans.slice(startIndex);

  const defaultMostPopular = workspace.isShared ? "STUDIO" : "PRO";
  let mostPopular = targetPlan ? targetPlan : defaultMostPopular;

  let mostPopularIndex = offeredPlans.findIndex(
    (plan) => plan.tier === mostPopular,
  );
  if (workspacePlanIndex > mostPopularIndex) {
    mostPopular = workspacePlan.tier as PlanTier;
    mostPopularIndex = workspacePlanIndex;
  }

  const onToggleBillingPeriod = (e, value) => {
    if (!value) return;
    setTableBillingPeriod(value);
  };

  const lgTableCols = displayPlans.length + 1;
  const lgTableColWidthPercent = (1 / lgTableCols) * 100;

  return (
    <Box
      className={`pb-16 relative z-10 ${!onboarding && "pt-16"}`}
      data-cy="pricing-plans"
    >
      <div className="px-6 mx-auto xl:max-w-5xl lg:px-6">
        <div
          className={`w-full pb-5 ${
            !onboarding && "border-b border-layer-200 dark:border-layer-700"
          }`}
        >
          <div
            className={`w-full flex space-x-4 ${
              onboarding
                ? "justify-center items-center"
                : "flex-col space-y-5 lg:space-y-0 lg:mt-0 lg:flex-row lg:justify-between lg:items-end"
            }`}
          >
            {!onboarding && (
              <div>
                <h1 className="mb-2 text-2xl font-medium tracking-tight text-layer-900 dark:text-layer-200">
                  Plans
                </h1>
                <PlanSummary plan={workspacePlan} workspace={workspace} />
              </div>
            )}

            <div
              className={classNames({
                "w-44": onboarding,
                "w-40": !onboarding,
              })}
            >
              <ToggleButtonGroup
                value={tableBillingPeriod}
                exclusive
                onChange={onToggleBillingPeriod}
                className={classNames(
                  "grid grid-cols-2 gap-x-1 rounded-full text-center text-xs font-semibold leading-5",
                  onboarding
                    ? "p-1 ring-1 ring-inset ring-layer-300 dark:ring-layer-700 dark:bg-layer-950"
                    : "p-0.5 bg-layer-300 dark:bg-layer-800",
                )}
              >
                {billingPeriods.map((option) => (
                  <ToggleButton
                    key={option.value}
                    value={option}
                    className={classNames(
                      option.value === tableBillingPeriod.value
                        ? onboarding
                          ? "bg-layer-600 text-layer-50"
                          : "bg-white text-layer-700"
                        : "text-layer-500 hover:text-layer-600 dark:text-layer-300",
                      onboarding ? "py-1 px-1 h-6" : "py-1 px-1 h-6",
                      "cursor-pointer rounded-full normal-case border-none",
                    )}
                  >
                    <span>{option.label}</span>
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
          </div>
        </div>

        {/* xs to lg */}
        <div className="max-w-md mx-auto mt-12 space-y-8 sm:mt-16 lg:hidden">
          {displayPlans.map((plan) => {
            return (
              <section
                key={plan.tier}
                className={classNames(
                  {
                    "rounded-xl bg-layer-950/5 ring-1 ring-inset ring-white/10 dark:ring-layer-700":
                      plan.tier === mostPopular,
                  },
                  "p-6",
                )}
                data-cy="pricing-plan-card"
              >
                <h3 className="text-sm font-semibold leading-6 text-layer-900 dark:text-layer-200">
                  {plan.name}
                </h3>
                <p className="flex items-baseline mt-2 gap-x-1">
                  <PriceBanner
                    plan={plan}
                    period={tableBillingPeriod.value}
                    discountPercent={workspace.billingInfo.discountPercent}
                    isPerSeat={plan.canUseForSharedWorkspace}
                  />
                </p>
                <p className="text-layer-300 leading-tight text-sm mt-1.5">
                  {getSubtitle(plan)}
                </p>
                <SelectPlanButtons
                  plan={plan}
                  targetPlan={targetPlan}
                  isWorkspaceShared={workspace.isShared}
                  billingPeriod={tableBillingPeriod.value}
                  currentPlanTier={workspace?.planTier}
                  billingInfo={workspace?.billingInfo}
                  onClick={() =>
                    selectPlan(
                      plan,
                      tableBillingPeriod.value,
                      workspace.id,
                      false,
                    )
                  }
                />
                <ul
                  role="list"
                  className="mt-10 space-y-4 text-sm leading-6 text-layer-900 dark:text-layer-50"
                >
                  {tableSections.map((section) => (
                    <li key={section.name}>
                      <ul role="list" className="space-y-4">
                        {section.features.map((feature) => {
                          let planFeature;
                          if (feature.getTierValue) {
                            planFeature = feature.getTierValue(plan);
                          } else {
                            planFeature = feature.tiers[plan.tier];
                          }
                          return planFeature ? (
                            <li key={feature.name} className="flex gap-x-3">
                              <FontAwesomeIcon
                                icon={regular("check")}
                                className="flex-none w-5 h-6 text-violet-600"
                                aria-hidden="true"
                              />
                              <span>
                                {feature.name}{" "}
                                {typeof planFeature === "string" ? (
                                  <span className="text-sm leading-6 text-layer-50 font-medium">
                                    {planFeature}
                                  </span>
                                ) : null}
                              </span>
                            </li>
                          ) : null;
                        })}
                      </ul>
                    </li>
                  ))}
                </ul>
              </section>
            );
          })}
        </div>

        {/* lg+ */}
        <div className="hidden mt-20 isolate lg:block">
          <div className="relative -mx-6">
            <div className="absolute inset-0 flex -z-10">
              <div
                className={classNames(`flex px-0`)}
                aria-hidden="true"
                style={{
                  width: `${lgTableColWidthPercent}%`,
                  marginLeft: `${
                    (mostPopularIndex + 1) * lgTableColWidthPercent
                  }%`,
                }}
              >
                <div className="w-full border-t rounded-t-lg border-x border-layer-300 bg-layer-100 dark:border-layer-700 dark:bg-layer-800" />
              </div>
            </div>
            <table className="w-full text-left table-fixed">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col
                  style={{
                    width: `${lgTableColWidthPercent}%`,
                  }}
                />
                {displayPlans.map((plan) => (
                  <col
                    key={plan.tier}
                    style={{
                      width: `${lgTableColWidthPercent}%`,
                    }}
                  />
                ))}
              </colgroup>
              <thead>
                <tr>
                  <td />
                  {displayPlans.map((plan) => {
                    const isCurrent = plan.tier === workspace?.planTier;

                    let infoBadge: React.ReactNode = null;

                    if (isCurrent) {
                      let badgeText = "Active";
                      if (workspace.billingInfo?.isCancelled) {
                        badgeText = "Cancelled";
                      } else if (workspace.billingInfo?.isPaused) {
                        badgeText = "Paused";
                      } else if (workspace.billingInfo?.isTrial) {
                        badgeText = "Trialing";
                      } else if (
                        !workspace.billingInfo?.isSubscribed &&
                        !plan.isFree
                      ) {
                        badgeText = "Trialing";
                      }
                      infoBadge = (
                        <div
                          className={classNames(
                            "inline-flex leading-none text-sm rounded-sm font-normal px-0.5 border ml-2 -translate-y-px",
                            "bg-layer-800 border-layer-700 text-layer-300",
                          )}
                        >
                          {badgeText}
                        </div>
                      );
                    }
                    return (
                      <th
                        key={plan.tier}
                        scope="col"
                        className="px-6 pt-6 xl:px-3 xl:pt-4"
                        data-cy="pricing-plan-card"
                      >
                        <div className="text-lg font-semibold leading-7 text-layer-700 dark:text-layer-50">
                          {plan.name}

                          {infoBadge}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="sr-only">Price</span>
                  </th>
                  {displayPlans.map((plan) => {
                    return (
                      <td
                        key={plan.tier}
                        className="px-6 pt-2 align-top xl:px-3"
                      >
                        <p className="h-14 text-sm text-layer-800/90 dark:text-layer-200 leading-tight">
                          {getSubtitle(plan)}
                        </p>

                        <div className="pt-2 pb-1 flex items-baseline gap-x-1 text-layer-900 dark:text-layer-50">
                          <PriceBanner
                            plan={plan}
                            period={tableBillingPeriod.value}
                            discountPercent={
                              workspace.billingInfo.discountPercent
                            }
                            isPerSeat={plan.canUseForSharedWorkspace}
                          />
                        </div>

                        <SelectPlanButtons
                          plan={plan}
                          targetPlan={targetPlan}
                          isWorkspaceShared={workspace.isShared}
                          billingPeriod={tableBillingPeriod.value}
                          currentPlanTier={workspace?.planTier}
                          billingInfo={workspace?.billingInfo}
                          onClick={() =>
                            selectPlan(
                              plan,
                              tableBillingPeriod.value,
                              workspace.id,
                              false,
                            )
                          }
                        />
                      </td>
                    );
                  })}
                </tr>
                {tableSections.map((section, sectionIdx) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        scope="colgroup"
                        colSpan={lgTableCols}
                        className={classNames(
                          sectionIdx === 0 ? "pt-8" : "pt-16",
                          "pb-4 text-sm font-bold leading-6 text-layer-900 dark:text-layer-50",
                        )}
                      >
                        {section.name}
                        <div className="absolute inset-x-0 h-px mt-4 bg-layer-300 dark:bg-layer-600/75" />
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.name}>
                        <th
                          scope="row"
                          className="py-4 text-sm font-normal leading-6 text-layer-700 dark:text-layer-300"
                        >
                          <Tooltip
                            title={feature.tooltip}
                            followCursor
                            placement="top"
                            enterDelay={250}
                          >
                            <span className="underline cursor-help underline-offset-2 decoration-dotted decoration-layer-400 hover:decoration-layer-500 dark:decoration-layer-700 dark:hover:decoration-layer-600">
                              {feature.name}
                            </span>
                          </Tooltip>
                          <div
                            className={classNames(
                              "absolute inset-x-0 h-px bg-layer-300 dark:bg-layer-700 mt-4",
                            )}
                          />
                        </th>
                        {displayPlans.map((plan) => {
                          let planFeature;
                          if (feature.getTierValue) {
                            planFeature = feature.getTierValue(plan);
                          } else {
                            planFeature = feature.tiers[plan.tier];
                          }
                          return (
                            <td key={plan.tier} className="px-6 py-4 xl:px-3">
                              {typeof planFeature === "string" ? (
                                <div className="text-sm leading-6 text-center text-layer-800 dark:text-layer-200">
                                  {planFeature}
                                </div>
                              ) : (
                                <>
                                  {planFeature === true ? (
                                    <FontAwesomeIcon
                                      icon={solid("check")}
                                      className="block w-4 h-4 mx-auto text-xs text-violet-600 dark:text-violet-400"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={solid("minus")}
                                      className="block w-4 h-4 mx-auto text-xs text-layer-600 dark:text-layer-300"
                                      aria-hidden="true"
                                    />
                                  )}

                                  <span className="sr-only">
                                    {planFeature === true
                                      ? "Included"
                                      : "Not included"}{" "}
                                    in {plan.name}
                                  </span>
                                </>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Box>
  );
}

function getSubtitle(plan: OfferedPlan) {
  switch (plan.tier) {
    case "FREE":
      return "Explore Layer’s core asset creation capabilities 40+ creative art styles";
    case "PRO":
      return "Teach AI your art style for consistent game assets in your solo project.";
    case "STUDIO":
      return "Create a team workspace and collaborate across one or more projects.";
    case "ENTERPRISE":
      return "Partner with the Layer team to optimize your artists' workflows.";
  }
}

function getTierValueWithSeats(amount: number, amountPerSeat: number) {
  if (amount >= 999999) {
    return "Unlimited";
  }
  if (amount === 0) {
    return (
      <FontAwesomeIcon
        icon={solid("minus")}
        className="block w-4 h-4 mx-auto text-xs text-layer-900"
        aria-hidden="true"
      />
    );
  }
  let val = amount.toLocaleString("en-US");
  if (amountPerSeat > 0) {
    val += ` + ${amountPerSeat} per seat`;
  }
  return val;
}
