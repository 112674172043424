type DefaultProfileImageProps = {
  workspaceName: string;
  className?: string;
};

export default function DefaultProfileImage({
  workspaceName,
  className = "text-base",
}: DefaultProfileImageProps) {
  return (
    <picture
      className={`w-full h-full flex items-center justify-center bg-purple-200 select-none ${className}`}
    >
      <div className="text-center font-bold text-purple-600 capitalize">
        {workspaceName?.slice(0, 1) || "w"}
      </div>
    </picture>
  );
}
