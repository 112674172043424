import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/system";

import {
  Box,
  IconButton,
  Tooltip,
  Typography,
} from "../../../../components/ui-v2";
import { GraphqlWsConnectionInfo } from "../../../graphql/GraphqlWsContext";

export const WebsocketConnectionIndicator = ({
  info,
}: {
  info: GraphqlWsConnectionInfo;
}) => {
  const { status, latencyMs } = info;
  const theme = useTheme();
  const statusInfo = `Connection status: ${status}`;
  const latencyInfo =
    status === "connected" && latencyMs ? `Latency: ${latencyMs}ms` : null;

  const isConnecting = status === "connecting";
  let color = theme.palette.success[300];
  if (status === "error" || status === "disconnected") {
    color = theme.palette.error[400];
  } else if (status === "connecting" || latencyMs > 500) {
    color = theme.palette.warning[400];
  }
  return (
    <Tooltip
      title={
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography fontWeight={500} color="inherit">
            {statusInfo}
          </Typography>
          <Typography fontWeight={400} color="inherit">
            {latencyInfo}
          </Typography>
        </Box>
      }
      placement="top"
      arrow
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "36px",
          height: "36px",
          border: "1px solid",
          borderRadius: "50%",
          borderColor: "secondary.600",
          backgroundColor: "secondary.900",
          color: "secondary.300",
          fontSize: "16px",
          zIndex: "100",
          "& svg": {
            color,
          },
        }}
      >
        <IconButton
          aria-label=""
          size="medium"
          sx={{
            borderRadius: "50%",
            width: "28px",
            borderWidth: 0,
            backgroundColor: "secondary.900",
          }}
        >
          {isConnecting ? (
            <FontAwesomeIcon icon={regular("rotate")} spin={true} />
          ) : (
            <FontAwesomeIcon icon={regular("globe")} />
          )}
        </IconButton>
      </Box>
    </Tooltip>
  );
};
