import { Theme } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { useBoolean } from "usehooks-ts";

import { ImageGrid, Link } from "../";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import { ForgeButton } from "./components/ForgeButton/ForgeButton";
import { ImportIndicator } from "./components/ImportIndicator/ImportIndicator";
import { StatusIndicator } from "./components/StatusIndicator/StatusIndicator";
import { StyleActionsMenu } from "./components/StyleActionsMenu/StyleActionsMenu";
import { StyleTileFooter } from "./components/StyleTileFooter/StyleTileFooter";
import { StyleActions, StyleStatus } from "./types";

export interface StyleTileProps extends StyleActions {
  styleId: string;
  styleSlug: string;
  styleName: string;
  styleStatus: StyleStatus;
  styleImported: boolean;
  styleThumbnails: string[];
  workspaceName: string;
  workspaceDisplayName: string;
  workspaceLogoSrc?: string;
  onClickStyle?: (event: React.MouseEvent) => void;
}

const Container = styled("div")(({ theme }: { theme: Theme }) => ({
  display: "flex",
  flexDirection: "column",
  transform: "scale(1)",
  transition: theme.transitions.create(["transform"]),

  "&:focus-within,&:hover": {
    transform: "scale(1.05)",
  },
}));

const ImageOuterContainer = styled("div")(({ theme }: { theme: Theme }) => ({
  display: "inline-block",
  position: "relative",
  width: "100%",
  flex: 1,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.secondary["600"]}`,
  transition: theme.transitions.create(["border"]),

  "&:hover": {
    border: `1px solid ${theme.palette.secondary["300"]}`,

    "& .Layer-ForgeButton": {
      opacity: 1,
    },
  },
}));

const ImageInnerContainer = styled(Link)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
}));

export const StyleTile = (props: StyleTileProps) => {
  const {
    styleSlug,
    styleName,
    styleStatus,
    styleThumbnails,
    styleImported,
    workspaceName,
    workspaceDisplayName,
    workspaceLogoSrc,
    onEdit,
    onForge,
    onUnlink,
    onImport,
    onLocateInDrive,
    onView,
    onDelete,
    onDuplicate,
    onCancelTraining,
    onClickStyle,
  } = props;

  const [anchor, setAnchor] = useState(null);
  const isDeleteConfirmDialogOpen = useBoolean();

  const handleImageClick = (event: React.MouseEvent) => {
    if (onClickStyle) {
      event.preventDefault();
      onClickStyle(event);
    }
  };

  const handleContextMenuOpen = (event: React.MouseEvent) => {
    const isRightClick = event.nativeEvent.button === 2;

    if (isRightClick) {
      event.preventDefault();
      setAnchor({ x: event.clientX, y: event.clientY });
    }
  };

  const handleActionsMenuOpen = (
    event: React.MouseEvent | React.KeyboardEvent,
  ) => {
    const node = event.target as HTMLElement;
    const { left, top, width, height } = node.getBoundingClientRect();
    setAnchor({ x: left + width, y: top + height });
  };

  const handleActionsMenuClose = () => {
    setAnchor(null);
  };

  const handleConfirmDelete = (event) => {
    onDelete(event);
    handleActionsMenuClose();
  };

  return (
    <Container>
      <ImageOuterContainer onContextMenu={handleContextMenuOpen}>
        {styleStatus === "COMPLETE" && onForge && (
          <ForgeButton
            styleName={styleName}
            open={!!onForge}
            onClick={onForge}
          />
        )}
        <ImageInnerContainer
          onClick={handleImageClick}
          href={`/${workspaceName}/styles/${styleSlug}`}
        >
          <StatusIndicator styleStatus={styleStatus} />
          {styleImported && <ImportIndicator />}
          <ImageGrid imageSrcs={styleThumbnails} rows={2} columns={2} />
        </ImageInnerContainer>
      </ImageOuterContainer>
      <StyleTileFooter
        styleName={styleName}
        workspaceName={workspaceDisplayName}
        workspaceLogoSrc={workspaceLogoSrc}
        onActionMenuOpen={handleActionsMenuOpen}
      />
      <StyleActionsMenu
        open={!!anchor}
        anchor={anchor}
        styleStatus={styleStatus}
        onEdit={onEdit}
        onLocateInDrive={onLocateInDrive}
        onView={onView}
        onForge={onForge}
        onUnlink={onUnlink}
        onImport={onImport}
        onDuplicate={onDuplicate}
        onCancelTraining={onCancelTraining}
        onDelete={onDelete && isDeleteConfirmDialogOpen.setTrue}
        onClose={handleActionsMenuClose}
      />
      <ConfirmDialog
        title="Are you sure you want to delete this style?"
        description="Once a style has been deleted, it cannot be be retrieved"
        confirmButtonProps={{
          color: "error",
        }}
        open={isDeleteConfirmDialogOpen.value}
        onConfirm={handleConfirmDelete}
        onClose={isDeleteConfirmDialogOpen.setFalse}
      />
    </Container>
  );
};

export default StyleTile;
