import { create } from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";

interface NavigationState {
  expanded: boolean;
  userToggled: boolean;
  toggleExpanded: () => void;
  setExpanded: (expanded: boolean, userInitiated?: boolean) => void;
  resetExpanded: () => void;
}

export const useNavigationStore = create<NavigationState>()(
  persist(
    subscribeWithSelector((set) => ({
      expanded: false,
      userToggled: false,

      toggleExpanded: () => {
        set((state) => {
          const newExpanded = !state.expanded;
          return { expanded: newExpanded, userToggled: true };
        });
      },

      setExpanded: (expanded: boolean) => {
        set({ expanded });
      },

      resetExpanded: () => {
        set({ expanded: true, userToggled: false });
      },
    })),
    {
      name: "navigation",
      getStorage: () => localStorage,
    },
  ),
);
