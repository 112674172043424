import {
  Asset,
  Directory,
  Error,
  File,
  Inference,
  Link,
  PersonalAccessToken,
  RawImage,
  Session,
  StringWrapper,
  Style,
  StylesConnection,
  User,
  Workspace,
} from "../components/graphql/schema.graphql";
import {
  DriveAsset,
  DriveDirectory,
  DriveFile,
  DriveStyle,
} from "../features/drive/module";

export interface TypedObject {
  __typename: string;
}

type TypedPartial<T> = Partial<T> & TypedObject;

export function isError<E extends TypedPartial<Error>>(
  obj: E | TypedObject,
): obj is E {
  return obj?.__typename === "Error";
}

export function errorAsUndefined<T extends TypedObject>(
  obj: T,
): Exclude<T, Partial<Error>> | undefined {
  if (obj?.__typename === "Error") {
    return undefined;
  }
  return obj as Exclude<T, Partial<Error>>;
}

export function isStringWrapper<O extends TypedPartial<StringWrapper>>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "StringWrapper";
}

export function isLink<O extends TypedPartial<Link>>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "Link";
}

export function isRawImage<O extends TypedPartial<RawImage>>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "RawImage";
}

export function isUser<O extends TypedPartial<User>>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "User";
}

export function isWorkspace<O extends TypedPartial<Workspace>>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "Workspace";
}

export function isStyle<O extends TypedPartial<Style>>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "Style";
}

export function isStyles<O extends TypedPartial<StylesConnection>>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "StylesConnection";
}

export function isInference<O extends TypedPartial<Inference>>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "Inference";
}

export function isDirectory<O extends TypedPartial<Directory>>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "Directory";
}

export function isFile<O extends TypedPartial<File>>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "File";
}

export function isAsset<O extends TypedPartial<Asset>>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "Asset";
}

export function isSession(obj: TypedObject): obj is Session {
  return obj?.__typename === "Session";
}

export function isDriveFile<O extends DriveFile>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "File";
}

export function isDriveDirectory<O extends DriveDirectory>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "Directory";
}
export function isDriveAsset<O extends DriveAsset>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "Asset";
}
export function isDriveStyle<O extends DriveStyle>(
  obj: O | TypedObject,
): obj is O {
  return obj?.__typename === "Style";
}

export function isPersonalAccessToken<
  O extends TypedPartial<PersonalAccessToken>,
>(obj: O | TypedObject): obj is O {
  return obj?.__typename === "PersonalAccessToken";
}

export function getErrorMessage(obj: TypedObject) {
  if (isError(obj)) {
    return obj.message;
  }
  return null;
}
