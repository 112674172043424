import { styled } from "@mui/system";

import { Box } from "../../../ui-v2";
import { HelpCenterButton } from "./components/HelpCenterButton/HelpCenterButton";
import { ReleaseNotesBox } from "./components/ReleaseNotesBox/ReleaseNotesBox";

const StickyFooterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  justifyContent: "flex-end",
  position: "sticky",
  bottom: 0,
  marginBottom: theme.spacing(3),
}));

export const NavFooter = () => (
  <StickyFooterContainer>
    <ReleaseNotesBox />
    <HelpCenterButton />
  </StickyFooterContainer>
);
