import { PropsWithChildren } from "react";

type ForgeIconProps = PropsWithChildren<{
  weight?: "solid" | "regular" | "light" | "thin";
  className?: string;
}>;

export function ForgeIcon({ weight = "solid", className }: ForgeIconProps) {
  const commonProps = {
    viewBox: "0 0 512 512",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    style: { width: "1em", height: "1em" },
    className,
  };
  let icon: JSX.Element;
  switch (weight) {
    case "solid":
      icon = (
        <svg {...commonProps}>
          <g clipPath="url(#clip0_2_217)">
            <path
              d="M357.666 142.948C369.51 129.794 368.451 109.598 355.298 97.7547C342.144 85.9111 321.948 86.9695 310.105 100.123L297.525 114.094L218.207 17.9166C212.307 10.7202 203.448 6.5112 194.231 6.28615C185.015 6.06111 176.012 9.93166 169.79 16.8429L41.3164 159.527C35.0935 166.438 32.2521 175.721 33.3724 184.938C34.4927 194.155 39.6716 202.452 47.4453 207.567L174.344 291.551C177.057 293.32 179.435 295.461 181.404 297.907L278.266 415.399C284.166 422.596 293.025 426.805 302.242 427.03C311.459 427.255 320.461 423.384 326.684 416.473L455.157 273.789C461.38 266.878 464.221 257.595 463.101 248.378C461.981 239.161 456.802 230.864 449.028 225.749L345.086 156.919L357.666 142.948Z"
              fill="currentColor"
            />
            <path
              d="M177.335 343.225L156.767 318.246L129.774 300.401L10.3343 433.052C-1.50926 446.206 -0.450862 466.401 12.7028 478.245C25.8565 490.088 46.052 489.03 57.8956 475.876L177.335 343.225Z"
              fill="currentColor"
            />
            <path
              d="M448 67.9999C444.3 67.9999 441.1 70.4999 440.2 74.0999L433.4 101.4L406.1 108.2C402.5 109.1 400 112.3 400 116C400 119.7 402.5 122.9 406.1 123.8L433.4 130.6L440.2 157.9C441.1 161.5 444.3 164 448 164C451.7 164 454.9 161.5 455.8 157.9L462.6 130.6L489.9 123.8C493.5 122.9 496 119.7 496 116C496 112.3 493.5 109.1 489.9 108.2L462.6 101.4L455.8 74.0999C454.9 70.4999 451.7 67.9999 448 67.9999Z"
              fill="currentColor"
            />
            <path
              d="M432 352C428.4 352 425.3 354.4 424.3 357.8L409.5 409.5L357.8 424.3C354.4 425.3 352 428.4 352 432C352 435.6 354.4 438.7 357.8 439.7L409.5 454.5L424.3 506.2C425.3 509.6 428.4 512 432 512C435.6 512 438.7 509.6 439.7 506.2L454.5 454.5L506.2 439.7C509.6 438.7 512 435.6 512 432C512 428.4 509.6 425.3 506.2 424.3L454.5 409.5L439.7 357.8C438.7 354.4 435.6 352 432 352Z"
              fill="currentColor"
            />
            <path
              d="M184 416C180.3 416 177.1 418.5 176.2 422.1L169.4 449.4L142.1 456.2C138.5 457.1 136 460.3 136 464C136 467.7 138.5 470.9 142.1 471.8L169.4 478.6L176.2 505.9C177.1 509.5 180.3 512 184 512C187.7 512 190.9 509.5 191.8 505.9L198.6 478.6L225.9 471.8C229.5 470.9 232 467.7 232 464C232 460.3 229.5 457.1 225.9 456.2L198.6 449.4L191.8 422.1C190.9 418.5 187.7 416 184 416Z"
              fill="currentColor"
            />
          </g>
        </svg>
      );
      break;
    case "regular":
      icon = (
        <svg {...commonProps}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M355.285 97.7883C365.168 106.688 365.96 121.799 357.061 131.683L338.325 152.491L448.941 225.715C465.001 236.274 467.984 259.413 455.07 273.756L326.597 416.44C313.749 430.708 290.432 430.301 278.179 415.366L181.458 297.867C179.414 295.354 177.036 293.213 174.398 291.51L47.4322 207.601C31.3719 197.042 28.3891 173.903 41.3033 159.56L169.776 16.8765C182.691 2.5338 206.015 3.08161 218.194 17.9502L302.654 120.373L321.39 99.5647C330.289 89.6808 345.401 88.8889 355.285 97.7883ZM89.4201 177.856L200.838 251.534L200.913 251.601C207.512 255.928 213.457 261.281 218.525 267.459L303.446 370.566L407.027 255.527L295.535 181.782C288.935 177.455 282.99 172.102 277.922 165.924L193.001 62.8173L89.4201 177.856Z"
            fill="currentColor"
          />
          <path
            d="M156.753 318.279L172.18 337.014L46.5843 476.502C37.6848 486.386 22.5735 487.178 12.6896 478.278C2.80582 469.379 2.01387 454.268 10.9133 444.384L136.509 304.896L156.753 318.279Z"
            fill="currentColor"
          />
          <path
            d="M448 67.9998C444.3 67.9998 441.1 70.4998 440.2 74.0998L433.4 101.4L406.1 108.2C402.5 109.1 400 112.3 400 116C400 119.7 402.5 122.9 406.1 123.8L433.4 130.6L440.2 157.9C441.1 161.5 444.3 164 448 164C451.7 164 454.9 161.5 455.8 157.9L462.6 130.6L489.9 123.8C493.5 122.9 496 119.7 496 116C496 112.3 493.5 109.1 489.9 108.2L462.6 101.4L455.8 74.0998C454.9 70.4998 451.7 67.9998 448 67.9998Z"
            fill="currentColor"
          />
          <path
            d="M432 352C428.4 352 425.3 354.4 424.3 357.8L409.5 409.5L357.8 424.3C354.4 425.3 352 428.4 352 432C352 435.6 354.4 438.7 357.8 439.7L409.5 454.5L424.3 506.2C425.3 509.6 428.4 512 432 512C435.6 512 438.7 509.6 439.7 506.2L454.5 454.5L506.2 439.7C509.6 438.7 512 435.6 512 432C512 428.4 509.6 425.3 506.2 424.3L454.5 409.5L439.7 357.8C438.7 354.4 435.6 352 432 352Z"
            fill="currentColor"
          />
          <path
            d="M184 416C180.3 416 177.1 418.5 176.2 422.1L169.4 449.4L142.1 456.2C138.5 457.1 136 460.3 136 464C136 467.7 138.5 470.9 142.1 471.8L169.4 478.6L176.2 505.9C177.1 509.5 180.3 512 184 512C187.7 512 190.9 509.5 191.8 505.9L198.6 478.6L225.9 471.8C229.5 470.9 232 467.7 232 464C232 460.3 229.5 457.1 225.9 456.2L198.6 449.4L191.8 422.1C190.9 418.5 187.7 416 184 416Z"
            fill="currentColor"
          />
        </svg>
      );
      break;
    case "light":
      icon = (
        <svg {...commonProps}>
          <g clipPath="url(#clip0_2_209)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M448.555 225.415C450.106 226.307 451.561 227.474 452.862 228.645C465.718 240.221 467.214 260.379 455.243 273.822L326.77 416.506C314.725 429.883 294.462 430.449 281.642 418.907C280.249 417.652 279.061 416.438 278.011 414.989L180.203 292.271L47.9336 207.826C46.3822 206.934 44.9887 205.823 43.6882 204.652C30.7798 193.029 29.2843 172.87 41.3287 159.494L169.802 16.8099C181.846 3.43332 202.08 2.84016 214.899 14.3827C216.2 15.5536 217.508 16.88 218.561 18.3259L306.223 128.327L332.713 98.9059C338.631 92.3335 350.075 93.0083 355.979 98.3239C361.882 103.639 362.412 113.746 356.494 120.318L330.003 149.739L448.555 225.415ZM201.825 268.184L302.916 395.028L431.389 252.344L294.673 165.065L193.582 38.222L65.1092 180.906L201.825 268.184Z"
              fill="currentColor"
            />
            <path
              d="M140.004 312.932C145.892 306.392 156.061 305.859 162.6 311.747C169.14 317.636 169.673 327.804 163.784 334.344L34.6427 476.425C28.7249 482.998 17.9498 482.925 12.0463 477.61C6.14273 472.294 5.61308 462.188 11.5309 455.616L140.004 312.932Z"
              fill="currentColor"
            />
            <path
              d="M432 134H400C391.2 134 384 126.84 384 118C384 109.16 391.2 102 400 102H432V70.0001C432 61.1561 439.2 54.0001 448 54.0001C456.8 54.0001 464 61.1561 464 70.0001V102H496C504.8 102 512 109.156 512 118C512 126.844 504.844 134 496 134H464V166C464 174.8 456.8 182 448 182C439.2 182 432 174.8 432 166V134Z"
              fill="currentColor"
            />
            <path
              d="M448 416H496C504.8 416 511.99 424.952 511.99 432.9C511.99 440.848 504.844 448 496 448H448V496C448 504.8 440.843 512 432 512C423.157 512 416 504.844 416 496V448H368C359.2 448 352.01 439.048 352.01 431.1C352.01 423.152 359.156 416 368 416H416V368C416 359.2 423.2 352 432 352C440.8 352 448 359.2 448 368V416Z"
              fill="currentColor"
            />
            <path
              d="M135 464H167V496C167 504.8 174.2 512 183 512C191.8 512 199 504.8 199 496V464H231C239.844 464 247 456.844 247 448C247 439.156 239.8 432 231 432H199V400C199 391.156 191.8 384 183 384C174.2 384 167 391.156 167 400V432H135C126.2 432 119 439.16 119 448C119 456.84 126.2 464 135 464Z"
              fill="currentColor"
            />
          </g>
        </svg>
      );
      break;
    case "thin":
      icon = (
        <svg {...commonProps}>
          <g clipPath="url(#clip0_2_222)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M460.175 228.049C460.374 228.234 460.568 228.424 460.849 228.678C473.706 240.254 475.154 260.464 463.157 273.789L334.684 416.473C322.686 429.797 302.405 430.442 289.556 418.873C289.439 418.767 289.401 418.756 289.365 418.745C288.974 418.624 288.908 418.604 186.625 293.925C51.9461 205.241 51.9272 205.186 51.8106 204.849C51.8 204.819 51.7886 204.786 51.6758 204.684C38.8343 193.122 37.3187 172.851 49.3163 159.527L177.789 16.8428C189.787 3.5182 210.038 2.84632 222.887 14.4156C223.168 14.6681 223.378 14.842 223.583 15.0215C225.724 16.8923 227.323 19.3816 302.046 117.959L318.105 100.123C329.928 86.9917 350.166 85.9311 363.298 97.7546C376.429 109.578 377.49 129.816 365.666 142.947L349.607 160.783C455.5 224.818 458.115 226.134 460.175 228.049ZM352.591 109.645C346.034 103.741 335.897 104.275 329.995 110.829L313.936 128.665L337.717 150.077L353.776 132.241C359.677 125.687 359.149 115.549 352.591 109.645ZM298.293 404.806C305.265 413.573 316.87 412.346 322.794 405.767L451.267 263.083C457.349 256.328 456.944 244.673 447.993 238.982L340.045 173.704L290.225 128.845L213.8 28.0196C208.194 21.0612 196.585 19.8796 189.68 27.5489L61.2066 170.233C55.1175 176.995 55.494 188.638 64.5386 194.359L197.436 281.887L298.293 404.806Z"
              fill="currentColor"
            />
            <path
              d="M166.419 340.322C169.375 337.039 174.434 336.774 177.717 339.73C180.987 342.674 181.254 347.758 178.31 351.028L55.1894 487.767C49.2769 494.333 39.1594 494.863 32.5929 488.951L8.81231 467.539C2.24588 461.626 1.71564 451.509 7.62808 444.942L130.748 308.204C133.704 304.92 138.763 304.655 142.046 307.611C145.33 310.568 145.595 315.626 142.638 318.91L19.5183 455.648L43.2989 477.06L166.419 340.322Z"
              fill="currentColor"
            />
            <path
              d="M440 122H408C403.6 122 400.002 118.406 400.002 114C400.002 109.594 403.578 106 408 106H440V73.9998C440 69.5938 441.8 65.9998 447.1 65.9998C452.4 65.9998 456 69.5938 456 73.9998V106H488C492.4 106 495.988 109.594 495.988 114C495.988 118.406 492.422 122 488 122H456V154C456 158.41 450.6 162 447.1 162C443.6 162 440 158.41 440 154V122Z"
              fill="currentColor"
            />
            <path
              d="M440 424H504C508.4 424 511.989 427.599 511.989 432.005C511.989 436.411 508.422 440 504 440H440V504C440 508.4 436.423 512 432.001 512C427.579 512 424 508.406 424 504V440H360C355.6 440 352.012 436.401 352.012 431.995C352.012 427.589 355.578 424 360 424H424V360C424 355.6 427.579 352 432.001 352C436.423 352 440 355.594 440 360V424Z"
              fill="currentColor"
            />
            <path
              d="M142.512 472H174.512V504C174.512 508.41 178.112 512 181.612 512C185.112 512 190.512 508.41 190.512 504V472H222.512C226.934 472 230.5 468.406 230.5 464C230.5 459.594 226.912 456 222.512 456H190.512V424C190.512 419.594 186.912 416 181.612 416C176.312 416 174.512 419.594 174.512 424V456H142.512C138.09 456 134.514 459.594 134.514 464C134.514 468.406 138.112 472 142.512 472Z"
              fill="currentColor"
            />
          </g>
        </svg>
      );
      break;
  }

  return icon;
}

export default ForgeIcon;
