import { icon, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Backdrop, Fade, Modal, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { PropsWithChildren } from "react";

import { OfferedPlan, OfferedPlanConstraints } from "../../../utils/config";
import { capitalize } from "../../../utils/strings";
import { Button, Icon, IconButton } from "../";
import { UpgradePromptContent } from "./components/UpgradePromptContent";

export type PlanTier = "FREE" | "PRO" | "STUDIO" | "ENTERPRISE";
export type Size = "small" | "large";

export const ModalStyle = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.secondary[950],
  position: "absolute",
  width: "calc(100% - 128px)",
  height: "calc(100% - 128px)",
  minWidth: 800,
  margin: "64px",
  overflow: "auto",
  borderRadius: theme.spacing(4),
  padding: 40,
  paddingTop: 56,
  paddingBottom: 16,
}));

export const ModalTopBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary[900],
  position: "relative",
  width: "100%",
  maxWidth: 400,
  borderRadius: 8,
  padding: 40,
  margin: "0 auto",
  marginBottom: "-16px",
}));

export const ModalTopBoxBodyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary[300],
  textAlign: "center",
  marginTop: "12px",
}));

export const ModalTopBoxInner = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
});

export const UpgradePromptContainer = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
  zIndex: 1,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const UpgradePromptWrapper = styled("div")({
  position: "absolute",
  zIndex: 1,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

interface UpgradePrompt {
  upgradePromptIcon?: JSX.Element;
  upgradePromptTitleText: string;
  upgradePromptBodyText: string;
  upgradePromptButtonText: string;
  upgradePromptModalText?: string;
}

export interface UpgradePromptProps
  extends PropsWithChildren<{
    freeWorkspace: boolean;
    minimumPlanTierRequired: PlanTier;
    minimumIndex: number;
    offeredPlans: OfferedPlan[];
    upgradePromptContent: UpgradePrompt;
    backgroundContent?: JSX.Element;
    onClickUpgrade?: () => void;
    modalContent: JSX.Element;
    size?: Size;
  }> {}

function removeFullStop(str: string): string {
  if (str.endsWith(".")) {
    return str.slice(0, -1);
  }
  return str;
}

function getFeaturesByTier(
  tier: PlanTier,
): Array<keyof OfferedPlanConstraints> {
  switch (tier) {
    case "ENTERPRISE":
      return ["maxMembers", "maxWorkspaceStyles"];
    case "STUDIO":
      return [
        "maxMembers",
        "maxWorkspaceStyles",
        "inferenceSpeed",
        "trainingSpeed",
      ];
    case "PRO":
      return [
        "maxMonthlyInferences",
        "maxWorkspaceStyles",
        "inferenceWithCustomStyle",
      ];
    case "FREE":
      return ["maxMembers"];
    default:
      return [];
  }
}

function getTopFeaturesByTier(
  tier: PlanTier,
  constraints: OfferedPlanConstraints,
) {
  const keysToShow = getFeaturesByTier(tier);
  const top: { [key: string]: any } = {};
  let count = 0;

  for (const key of keysToShow) {
    if (constraints[key] && count < 4) {
      top[key] = constraints[key];
      count++;
    }
    if (count === 4) break;
  }

  if (tier === "PRO") {
    Object.assign(top, {
      ofStorage: "50GB",
    });
  }

  if (tier === "ENTERPRISE") {
    Object.assign(top, {
      soc2Compliance: true,
      userRolesAndPermissions: true,
    });
  }

  return top;
}
export default function UpgradePrompt({
  freeWorkspace,
  minimumPlanTierRequired = "FREE",
  minimumIndex,
  offeredPlans,
  upgradePromptContent,
  backgroundContent,
  onClickUpgrade,
  modalContent,
  size = "large",
}: UpgradePromptProps) {
  const [open, setOpen] = React.useState(false);

  const defaultIcon = freeWorkspace ? (
    <Icon
      icon={icon({
        name: "circle-user",
        family: "sharp",
        style: "regular",
      })}
    />
  ) : (
    <Icon
      icon={icon({
        name: "circle-up",
        family: "sharp",
        style: "regular",
      })}
    />
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const promptBodyText = `${removeFullStop(upgradePromptContent.upgradePromptBodyText)}.`;
  const promptModalText = `${removeFullStop(upgradePromptContent.upgradePromptModalText ? upgradePromptContent.upgradePromptModalText : upgradePromptContent.upgradePromptBodyText)}.`;

  const benefits = offeredPlans.map((plan) => ({
    tier: plan.tier,
    features: getTopFeaturesByTier(plan.tier, plan.constraints),
  }));

  if (size === "small") {
    return (
      <>
        <UpgradePromptContent
          upgradePromptContent={upgradePromptContent}
          defaultIcon={defaultIcon}
          onClickPrimaryButton={handleOpen}
          onClickSecondaryButton={handleOpen}
          minimumPlanTierRequired={minimumPlanTierRequired}
          offeredPlans={offeredPlans}
          benefits={benefits}
          promptBodyText={promptBodyText}
          minimumIndex={minimumIndex}
          size={size}
        />

        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          disableAutoFocus
        >
          <Fade in={open}>
            <ModalStyle>
              <IconButton
                size="large"
                aria-label="Close Dialog"
                tooltip={false}
                sx={{ position: "absolute", right: 8, top: 8 }}
                onClick={handleClose}
              >
                <Icon icon={regular("xmark")} />
              </IconButton>
              <ModalTopBox>
                <ModalTopBoxInner>
                  <Typography
                    variant="title2"
                    sx={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    {" "}
                    {promptBodyText}
                  </Typography>

                  <ModalTopBoxBodyText variant="small">
                    {promptModalText}
                  </ModalTopBoxBodyText>

                  <Button
                    onClick={onClickUpgrade}
                    variant="contained"
                    color="primary"
                    sx={{
                      fontWeight: "500",
                      margin: "20px auto",
                    }}
                    endIcon={
                      <Icon
                        icon={icon({
                          name: "arrow-right",
                          family: "sharp",
                          style: "solid",
                        })}
                      />
                    }
                  >
                    {upgradePromptContent.upgradePromptButtonText ??
                      `Upgrade to ${capitalize(minimumPlanTierRequired)}`}
                  </Button>
                </ModalTopBoxInner>
              </ModalTopBox>

              {modalContent}
            </ModalStyle>
          </Fade>
        </Modal>
      </>
    );
  } else
    return (
      <UpgradePromptWrapper>
        <UpgradePromptContainer>
          {backgroundContent && <>{backgroundContent}</>}

          <UpgradePromptContent
            upgradePromptContent={upgradePromptContent}
            defaultIcon={defaultIcon}
            onClickPrimaryButton={onClickUpgrade}
            onClickSecondaryButton={handleOpen}
            minimumPlanTierRequired={minimumPlanTierRequired}
            offeredPlans={offeredPlans}
            benefits={benefits}
            promptBodyText={promptBodyText}
            minimumIndex={minimumIndex}
            size={size}
          />

          <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
          >
            <Fade in={open}>
              <ModalStyle>
                <ModalTopBox>
                  <ModalTopBoxInner>
                    <Typography
                      variant="title2"
                      sx={{ textAlign: "center", fontWeight: "bold" }}
                    >{`Upgrade to ${capitalize(minimumPlanTierRequired)}`}</Typography>

                    <ModalTopBoxBodyText variant="small">
                      {promptModalText}
                    </ModalTopBoxBodyText>

                    <Button
                      onClick={onClickUpgrade}
                      variant="contained"
                      color="primary"
                      sx={{
                        fontWeight: "500",
                        margin: "20px auto",
                      }}
                      endIcon={
                        <Icon
                          icon={icon({
                            name: "arrow-right",
                            family: "sharp",
                            style: "solid",
                          })}
                        />
                      }
                    >
                      {upgradePromptContent.upgradePromptButtonText ??
                        `Upgrade to ${capitalize(minimumPlanTierRequired)}`}
                    </Button>
                  </ModalTopBoxInner>
                </ModalTopBox>

                {modalContent}
              </ModalStyle>
            </Fade>
          </Modal>
        </UpgradePromptContainer>
      </UpgradePromptWrapper>
    );
}
