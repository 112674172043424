import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/system";

import { Icon, Tooltip } from "../../..";

const Container = styled("div")(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary["200"],
  backgroundColor: theme.palette.secondary["950"],
  borderBottomLeftRadius: 6,
  padding: theme.spacing(1.5),
  position: "absolute",
  top: 0,
  right: 0,
  lineHeight: 1,

  "& > svg": {
    color: theme.palette.secondary["200"],
  },
}));

export const ImportIndicator = () => (
  <Tooltip title="This style is imported into your workspace">
    <Container>
      <Icon size="large" icon={regular("link")} />
    </Container>
  </Tooltip>
);
