import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { Link } from "react-router-dom";

const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
function parseLinks(markdown: string) {
  const content: JSX.Element[] = [];
  let lastIndex = 0;

  markdown.replace(
    linkRegex,
    (match: string, label: string, url: string, index: number): string => {
      if (index > lastIndex) {
        content.push(
          <span key={`text-${index}`} className="whitespace-pre">
            {markdown.slice(lastIndex, index)}
          </span>,
        );
      }

      content.push(
        <Link
          key={`link-${index}`}
          to={url}
          className="font-medium whitespace-pre underline underline-offset-4"
        >
          {label}
        </Link>,
      );

      lastIndex = index + match.length;
      return match;
    },
  );

  if (lastIndex < markdown.length) {
    content.push(
      <span key={`text-${lastIndex}`} className="whitespace-pre">
        {markdown.slice(lastIndex)}
      </span>,
    );
  }

  return <>{content}</>;
}

type ShowOptions = {
  autoHideDuration?: number;
};

type Message = string | JSX.Element;

export default function useShowSnackbar() {
  const { enqueueSnackbar } = useSnackbar();

  const showError = useCallback(
    (message: Message, { autoHideDuration = 4000 }: ShowOptions = {}) => {
      let content = message;
      if (typeof message === "string") {
        content = parseLinks(message);
      }
      enqueueSnackbar(content, {
        variant: "error",
        autoHideDuration,
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    },
    [enqueueSnackbar],
  );

  const showWarning = useCallback(
    (message: Message, { autoHideDuration = 4000 }: ShowOptions = {}) => {
      let content = message;
      if (typeof message === "string") {
        content = parseLinks(message);
      }
      enqueueSnackbar(content, {
        variant: "warning",
        autoHideDuration,
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    },
    [enqueueSnackbar],
  );

  const showSuccess = useCallback(
    (message: Message, { autoHideDuration = 4000 }: ShowOptions = {}) => {
      let content = message;
      if (typeof message === "string") {
        content = parseLinks(message);
      }
      enqueueSnackbar(content, {
        variant: "success",
        autoHideDuration,
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
    },
    [enqueueSnackbar],
  );

  return { showError, showWarning, showSuccess };
}
