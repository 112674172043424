import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pluralize from "pluralize";
import { Link } from "react-router-dom";

import { useBasicData } from "../../../../hooks";
import config from "../../../../utils/config";

export const AppWarningsBar = () => {
  const { workspace, workspaceError } = useBasicData();

  if (workspaceError || !workspace) {
    return null;
  }

  const workspacePlan =
    config.OFFERED_PLANS.find((plan) => workspace?.planTier === plan.tier) ??
    null;

  const messages = [];
  if (workspace.billingInfo?.periodEndAt) {
    // calculate days left
    const now = new Date();
    const end = new Date(workspace.billingInfo.periodEndAt);
    const diff = end.getTime() - now.getTime();
    const daysLeft = Math.ceil(diff / (1000 * 3600 * 24));
    if (daysLeft >= 0) {
      if (workspace.billingInfo?.isCancelled) {
        messages.push(
          <span key="cancelled">
            <span>Your plan is cancelled. You will have access until </span>
            <strong>{end.toLocaleDateString()}</strong>
            <span> (</span>
            <strong>{daysLeft}</strong>
            <span> days).</span>
          </span>,
        );
      } else if (workspace.billingInfo?.isTrial) {
        messages.push(
          <span key="trial">
            <span>Your free </span>
            <strong>{workspacePlan?.name || ""}</strong>
            <span> trial will end in </span>
            <strong>{daysLeft}</strong>
            <span> {pluralize("day", daysLeft)}</span>
            {workspace.billingInfo?.paymentMethodSummary ? (
              <span>
                {" "}
                and we will start charging your account at that time. You can
                manage your plan{" "}
              </span>
            ) : (
              <span>
                {" "}
                and we will cancel your plan at that time. You can add a payment
                method to keep your plan{" "}
              </span>
            )}
            <Link
              to={`/${workspace.name}/settings/billing`}
              className="underline underline-offset-2 decoration-dotted text-violet-600decoration-violet-400 dark:text-layer-200 dark:decoration-layer-400"
            >
              here
            </Link>
            <span>.</span>
          </span>,
        );
      }
    }
  }

  if (messages.length === 0) {
    return null;
  }

  return (
    <>
      <div className="w-full h-10 bg-layer-200 dark:bg-layer-700">
        <div className="w-full h-full flex flex-col items-center justify-center py-1.5 px-3">
          <p className="text-sm font-normal select-none whitespace-pre space-x-2 text-layer-700 dark:text-layer-300">
            <FontAwesomeIcon
              icon={solid("exclamation-circle")}
              className="text-xs mr-1 text-layer-600 dark:text-layer-300"
            />
            {messages}
          </p>
        </div>
      </div>
    </>
  );
};
