import { isEqual } from "lodash";
import { useRef } from "react";

type useOnValueChangedProps = {
  value?: any;
  onChanged?: () => void;
};

const useOnValueChanged = (props: useOnValueChangedProps) => {
  const { value, onChanged } = props;
  const valueRef = useRef(value);

  if (!isEqual(valueRef.current, value)) {
    valueRef.current = value;
    onChanged();
  }
};

export default useOnValueChanged;
