import { Chip as MuiChip, ChipProps as MuiChipProps } from "@mui/material";
import { styled } from "@mui/system";

declare module "@mui/material/Chip" {
  interface ChipPropsSizeOverrides {
    large: true;
  }
}

const getChipStyles = (size: "large" | "medium" | "small", theme) => {
  const { spacing } = theme;
  const sizeConfig = {
    small: {
      height: spacing(5),
      borderRadius: "3px",
    },
    medium: {
      height: spacing(6),
      borderRadius: "3px",
    },
    large: {
      height: spacing(7),
      borderRadius: spacing(9),
    },
  };

  return {
    ...sizeConfig[size],
    "& .MuiChip-label": {
      padding: 0,
    },
  };
};

const StyledChip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== "size",
})<MuiChipProps & { size?: "large" | "medium" | "small" }>(
  ({ theme, size }) => ({
    ...getChipStyles(size || "small", theme),
  }),
);

export const Chip = (
  props: MuiChipProps & { size?: "large" | "medium" | "small" },
) => {
  return <StyledChip {...props} />;
};
