import {
  icon,
  regular,
  solid,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { SxProps } from "@mui/material";

import { Box, Icon, IconProps } from "..";

interface ExportIconProps extends Omit<IconProps, "icon"> {
  variant?: "main" | "withoutBackground";
}

export function ExportIcon({ variant = "main", ...rest }: ExportIconProps) {
  if (variant === "main") {
    return (
      <Icon
        className="fa-fw"
        icon={icon({
          name: "arrow-down-to-line",
          style: "regular",
          family: "sharp",
        })}
        {...rest}
      />
    );
  } else if (variant === "withoutBackground") {
    const iconBoxProps: SxProps = {
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 1,
      width: "1em",
      height: "1em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    return (
      <Box
        component={"span"}
        sx={{
          position: "relative",
          width: "1em",
          height: "1em",
          "& .base-icon": {
            opacity: 1,
          },
          "& .action-icon": {
            opacity: 1,
            fontSize: "0.625em",
            marginTop: "0.275em",
            marginLeft: "0.275em",
          },
        }}
      >
        <Box component={"span"} className="base-icon" sx={iconBoxProps}>
          <Icon {...rest} icon={regular("square-dashed")} size="1x" />
        </Box>
        <Box component={"span"} className="action-icon" sx={iconBoxProps}>
          <Icon {...rest} icon={solid("arrow-down")} size="1x" />
        </Box>
      </Box>
    );
  }
}
