import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";

import {
  CreateStyleInput,
  DeleteStyleInput,
  UpdateStyleInput,
} from "../../components/graphql/schema.graphql";
import { getForgeUrl } from "../../components/inference/utils";
import { isError } from "../../utils/graphql";
import { useShowSnackbar } from "../";
import { CreateStyle2, DeleteStyle2, UpdateStyle2 } from "./queries.graphql";

export const useStyleActions = () => {
  const navigate = useNavigate();
  const { showError, showSuccess } = useShowSnackbar();
  const [createMutation, executeCreation] = useMutation(CreateStyle2);
  const [updateMutation, executeUpdate] = useMutation(UpdateStyle2);
  const [deleteMutation, executeDelete] = useMutation(DeleteStyle2);

  const handleMutation = async ({ mutation, successMessage, errorMessage }) => {
    try {
      const response = await mutation();

      if (response.error) {
        showError(errorMessage);
        return Promise.reject(response.error);
      } else if (successMessage) {
        showSuccess(successMessage);
        return Promise.resolve(response);
      }

      const data = response.data?.style;
      if (isError(data)) {
        showError(errorMessage);
        return Promise.reject(data.message);
      }
    } catch (err) {
      showError(errorMessage);
      return Promise.reject(err.message);
    }
  };

  const createStyle = async (input: CreateStyleInput) => {
    return handleMutation({
      mutation: () =>
        executeCreation({
          createStyleInput: input,
        }),
      successMessage: "Style created",
      errorMessage: "Unfortunately we could not create your style",
    });
  };

  const updateStyle = async (input: UpdateStyleInput) => {
    return handleMutation({
      mutation: () =>
        executeUpdate({
          updateStyleInput: input,
        }),
      successMessage: "Style updated",
      errorMessage: "Unfortunately we could not update your style",
    });
  };

  const deleteStyle = async (input: DeleteStyleInput) => {
    return handleMutation({
      mutation: () =>
        executeDelete({
          deleteStyleInput: input,
        }),
      successMessage: "Style deleted",
      errorMessage: "Unfortunately we could not delete your style",
    });
  };

  const navigateToStyleInLibrary = (
    workspaceName: string,
    directoryPath: string,
  ) => {
    if (directoryPath && workspaceName) {
      const isRootPath = directoryPath === "/";
      const pathSuffix = isRootPath ? "" : directoryPath;
      navigate(`/${workspaceName}/drive${pathSuffix}`);
    } else {
      navigate(`/${workspaceName}/drive`);
    }
  };

  const navigateToForgeWithStyle = (workspaceName: string, styleId: string) => {
    const forgeUrl = getForgeUrl({
      workspaceName,
      inputs: {
        parameters: {
          styles: [{ id: styleId, weight: 1 }],
        },
      },
    });

    navigate(forgeUrl);
  };

  return {
    createStyle: {
      execute: createStyle,
      state: createMutation,
    },
    updateStyle: {
      execute: updateStyle,
      state: updateMutation,
    },
    deleteStyle: {
      execute: deleteStyle,
      state: deleteMutation,
    },
    navigateToStyleInLibrary,
    navigateToForgeWithStyle,
  };
};
