import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/system";
import { useCallback, useState } from "react";

import {
  Box,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "../../../ui-v2";
import { useNavigationStore } from "../../state";
import WorkspaceSelectionButton from "./components/WorkspaceSelectionButton/WorkspaceSelectionButton";

const EXPANDED_GAP = "7px";

interface NavHeaderProps {
  workspace: any;
}

const SidebarHeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  width: "100%",
  paddingTop: theme.spacing(1.5),
  marginTop: theme.spacing(2),
  transition: "height 300ms ease-out, gap 300ms ease-out",
  color: theme.palette.secondary[300],
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  "&::-webkit-scrollbar-track": {
    display: "none",
  },
  ...(expanded
    ? {
        height: theme.spacing(10),
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(1.5),
      }
    : {
        height: "70px",
        gap: EXPANDED_GAP,
      }),
}));

const WorkspaceSelectionContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ expanded }) => ({
  userSelect: "none",
  transition: "margin-top 300ms ease-out",
  ...(expanded
    ? {
        marginTop: 0,
      }
    : {
        position: "relative",
        zIndex: 1,
      }),
}));

const ExpandCollapseButtonWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  transition: "margin-bottom 300ms ease-out",
  height: theme.spacing(7),
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  marginBottom: expanded ? theme.spacing(-8) : 0,
}));

const ExpandCollapseButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "expanded" && prop !== "isMenuOpen",
})<{
  expanded: boolean;
  isMenuOpen: boolean;
}>(({ theme, isMenuOpen }) => ({
  height: theme.spacing(7),
  width: theme.spacing(7),
  color: isMenuOpen
    ? theme.palette.secondary[100]
    : theme.palette.secondary[300],
  backgroundColor: isMenuOpen ? theme.palette.secondary[900] : "transparent",
  border: "none",
  boxShadow: isMenuOpen
    ? `inset 0 0 0 0.5px ${theme.palette.secondary[700]}`
    : "inset 0 0 0 0.5px transparent",
  fontWeight: "400",

  "&:hover": {
    color: theme.palette.secondary[100],
    backgroundColor: theme.palette.secondary[900],
    boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary[700]}`,
  },

  "& .MuiButton-icon": {
    color: theme.palette.secondary[300],
  },
}));

export const NavHeader = (props: NavHeaderProps) => {
  const { workspace } = props;
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const { expanded, toggleExpanded } = useNavigationStore();

  const handleContextMenu = useCallback((event) => {
    event.preventDefault();
    setMenuAnchor(event.currentTarget);
  }, []);

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleResetToggle = () => {
    useNavigationStore.getState().resetExpanded();
    handleCloseMenu();
  };

  const isMenuOpen = Boolean(menuAnchor);

  return (
    <SidebarHeaderContainer expanded={expanded}>
      <ExpandCollapseButtonWrapper expanded={expanded}>
        <ExpandCollapseButton
          expanded={expanded}
          isMenuOpen={isMenuOpen}
          variant="primary"
          size="medium"
          aria-label={expanded ? "Collapse sidebar" : "Expand sidebar"}
          onClick={toggleExpanded}
          onContextMenu={handleContextMenu}
        >
          <Box
            sx={{
              transition: "transform 0.3s ease-out",
              transform: expanded ? "rotate(0deg)" : "rotate(-180deg)",
            }}
          >
            <Icon
              style={{ marginLeft: "-1px" }}
              size="small"
              icon={icon({
                name: "angles-left",
                family: "sharp",
                style: "regular",
              })}
            />
          </Box>
        </ExpandCollapseButton>
        <Menu anchorEl={menuAnchor} open={isMenuOpen} onClose={handleCloseMenu}>
          <MenuItem onClick={handleResetToggle}>
            <ListItemIcon>
              <Icon
                size="medium"
                icon={icon({
                  name: "arrow-rotate-left",
                  family: "sharp",
                  style: "regular",
                })}
              />
            </ListItemIcon>
            <ListItemText>Use default behaviour</ListItemText>
          </MenuItem>
        </Menu>
      </ExpandCollapseButtonWrapper>

      {workspace?.myRole && (
        <WorkspaceSelectionContainer expanded={expanded}>
          <WorkspaceSelectionButton />
        </WorkspaceSelectionContainer>
      )}
    </SidebarHeaderContainer>
  );
};
