import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/system";

import { Box, Button, Icon, Typography } from "../../../../../ui-v2";
import { useNavigationStore } from "../../../../state";

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  justifyContent: "flex-start",
  backgroundColor: theme.palette.secondary[900],
  boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["700/50"]}`,
  transition: "all 0.3s ease-out",
  borderRadius: expanded ? "4px" : "99px",
  fontWeight: "400",

  "&:hover": {
    backgroundColor: theme.palette.secondary[900],
    boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary[700]}`,
  },

  "& .MuiButton-startIcon": {
    transition: "margin 0.3s ease-out",
    marginLeft: expanded ? theme.spacing(-2) : "-8.5px",
    marginRight: expanded ? theme.spacing(1) : 0,
    fontSize: "16px",
  },

  "& .MuiButton-icon": {
    color: theme.palette.secondary[300],
  },
}));

export const HelpCenterButton = () => {
  const { expanded } = useNavigationStore();
  return (
    <StyledButton
      expanded={expanded}
      variant="outlined"
      color="secondary"
      href="https://help.layer.ai/en/"
      target="_blank"
      fullWidth
      startIcon={
        <Box
          sx={{
            height: 28,
            width: 28,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            icon={icon({
              name: "circle-question",
              family: "sharp",
              style: "regular",
            })}
          />
        </Box>
      }
      endIcon={
        <>
          {expanded && (
            <Box
              sx={{
                fontSize: 14,
              }}
            >
              <Icon
                icon={icon({
                  name: "arrow-up-right",
                  family: "sharp",
                  style: "regular",
                })}
              />
            </Box>
          )}
        </>
      }
    >
      {expanded && (
        <Typography variant="small" fontWeight={500} color="secondary.100">
          Help Center
        </Typography>
      )}
    </StyledButton>
  );
};
