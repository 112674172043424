import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { PropsWithChildren, useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import AppBackground from "../../common/AppBackground";
import { Box } from "../../components/ui-v2";
import useBasicData from "../../hooks/useBasicData";
import usePathPattern from "../../hooks/usePathPattern";
import { useWebSocketConnectionInfo } from "../graphql/GraphqlWsContext";
import { Nav } from "../nav/Nav";
import TeamNoticesWrapper from "../notices/TeamNoticesWrapper";
import { AppWarningsBar } from "./components/AppWarningsBar/AppWarningsBar";
import { WebsocketConnectionIndicator } from "./components/ConnectionIndicator/ConnectionIndicator";
declare global {
  interface Window {
    Intercom: (command: string, ...params: any[]) => void;
  }
}

export default function Layout({ children = <Outlet /> }: PropsWithChildren) {
  const { user, workspace, featureFlags } = useBasicData();
  const { update } = useIntercom();
  const [searchParams, setSearchParams] = useSearchParams();
  const pathPattern = usePathPattern();
  const wsConnectionInfo = useWebSocketConnectionInfo();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isConnectionIndicatorVisible =
    wsConnectionInfo && featureFlags.showConnectionIndicator;

  // update intercom metadata
  useEffect(() => {
    if (user) {
      const customAttributes: Record<string, any> = {};
      if (user.personalWorkspace) {
        customAttributes.personal_workspace_tier =
          user.personalWorkspace.planTier;
      }
      if (workspace) {
        customAttributes.last_workspace_id = workspace.id;
        customAttributes.last_workspace_name = workspace.name;
        customAttributes.last_workspace_tier = workspace.planTier;
      }
      update({
        email: user.email,
        name: user.name,
        userId: user.id,
        userHash: user.intercomHash,
        avatar: {
          type: "avatar",
          imageUrl: user.profilePicture,
        },
        customAttributes,
      });
    }
  }, [user, workspace, update]);

  useEffect(() => {
    if (searchParams.has("onboard")) {
      // https://app.intercom.com/a/apps/sxjrhmhs/outbound/checklists/34234875
      window.Intercom?.("startChecklist", 34234875);
      setSearchParams((params) => {
        params.delete("onboard");
        return params;
      });
    }
  }, [searchParams, setSearchParams]);

  const sidebarIsHidden =
    [
      /^invite$/gm,
      /^inactive$/gm,
      /^tos$/gm,
      /^onboard$/gm,
      /^new$/gm,
      /^unverified$/gm,
    ].filter((pattern) => pattern.test(pathPattern)).length > 0;

  const sidebarIsCollapsed =
    [
      /^settings.*/gm,
      /^explore*/gm,
      /^:workspace\/welcome$/gm,
      /^:workspace\/pricing$/gm,
      /^:workspace\/forge$/gm,
      /^:workspace\/prompts$/gm,
      /^:workspace\/assets\/new$/gm,
      /^:workspace\/assets\/:assetId$/gm,
      /^:workspace\/styles.*/gm,
      /^:workspace\/drive.*/gm,
      /^:workspace\/session\/:sessionId$/gm,
      /^files\/:fileId/gm,
    ].filter((pattern) => pattern.test(pathPattern)).length > 0;

  return (
    <TeamNoticesWrapper>
      <div className="flex flex-col overflow-hidden">
        <AppWarningsBar />

        <div className="flex h-full grow" id="appMain">
          {!sidebarIsHidden && (
            <>
              {!!user && (
                <Nav isDefaultCollapsed={sidebarIsCollapsed || isSmall} />
              )}
            </>
          )}

          <AppBackground />
          <div className="relative w-full h-full overflow-auto">{children}</div>
          {isConnectionIndicatorVisible && (
            <Box
              sx={{
                position: "fixed",
                bottom: "12px",
                right: "55px",
                zIndex: "100",
              }}
            >
              <WebsocketConnectionIndicator info={wsConnectionInfo} />
            </Box>
          )}
        </div>
      </div>
    </TeamNoticesWrapper>
  );
}
