import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import classNames from "classnames";
import { ComponentProps } from "react";
import { useState } from "react";
import { IntercomProvider } from "react-use-intercom";

import layerConfig from "../../utils/config";

type LayerIntercomProviderProps = Omit<
  ComponentProps<typeof IntercomProvider>,
  "appId"
>;

export const LayerIntercomProvider = (props: LayerIntercomProviderProps) => {
  const [isMessengerOpen, setIsMessengerOpen] = useState(false);
  return (
    <>
      <Box
        className={classNames({
          "fixed flex items-center justify-center w-9 h-9 transition-all border rounded-full pointer-events-none bottom-3 right-3 group shadow-button z-[99999999999999999] text-sm":
            true,
          "bg-white border-layer-100 text-layer-600 hover:bg-layer-100 hover:border-layer-200 hover:text-layer-700 dark:border-layer-600 dark:bg-layer-950 dark:text-layer-300 dark:hover:bg-layer-700 dark:hover:border-layer-600 dark:hover:text-layer-200":
            !isMessengerOpen,
          "bg-purple-600 border-purple-700": isMessengerOpen,
        })}
      >
        {isMessengerOpen ? (
          <FontAwesomeIcon
            className="text-base text-purple-100"
            icon={icon({
              name: "xmark",
              family: "sharp",
              style: "solid",
            })}
          />
        ) : (
          <FontAwesomeIcon
            className="text-layer-300"
            icon={icon({
              name: "message-dots",
              family: "sharp",
              style: "solid",
            })}
          />
        )}
      </Box>

      <IntercomProvider
        {...props}
        onShow={() => setIsMessengerOpen(true)}
        onHide={() => setIsMessengerOpen(false)}
        appId={layerConfig.INTERCOM_APP_ID}
        autoBoot
      />
    </>
  );
};
