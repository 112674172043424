import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useCallback } from "react";

import {
  bindMenu,
  bindMenuTrigger,
  Icon,
  IconMenuItem,
  Link,
  useMenuState,
} from "..";
import { DataMenu } from "./DataMenu";
import { MenuItemData } from "./types";

interface DataMenuItemProps {
  data: MenuItemData;
  onCloseMenu?: () => void;
}

const DataMenuItemWithSubMenu = ({ data, onCloseMenu }: DataMenuItemProps) => {
  const subMenuState = useMenuState({
    openOnClick: true,
    useDefaultOrigins: true,
  });
  return [
    <IconMenuItem
      key="item"
      {...bindMenuTrigger(subMenuState)}
      label={data.name}
      leftIcon={data.icon}
      disabled={data.disabled}
      selected={data.selected}
      rightIcon={<Icon icon={solid("caret-right")} />}
    />,
    <DataMenu
      key="submenu"
      {...bindMenu(subMenuState)}
      items={data.subMenu}
      onCloseMenu={onCloseMenu}
    />,
  ];
};

export const DataMenuItem = ({ data, onCloseMenu }: DataMenuItemProps) => {
  const onClick = useCallback(() => {
    data.onClick?.();
    data.closeOnClick && onCloseMenu();
  }, [data.onClick, data.closeOnClick]);

  return data.subMenu ? (
    <DataMenuItemWithSubMenu data={data} onCloseMenu={onCloseMenu} />
  ) : (
    <IconMenuItem
      component={data.route ? Link : undefined}
      href={data.route || undefined}
      label={data.name}
      leftIcon={data.icon}
      disabled={data.disabled}
      selected={data.selected}
      onClick={onClick}
    />
  );
};
