import { useAuth0 } from "@auth0/auth0-react";
import { Client, createClient as createWSClient } from "graphql-ws";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

export interface GraphqlWsConnectionInfo {
  status: "connecting" | "connected" | "disconnected" | "error";
  latencyMs?: number;
}

export interface GraphqlWsClientContextType {
  client?: Client;
  connectionInfo: GraphqlWsConnectionInfo;
}

export const GraphqlWsClientContext = createContext<GraphqlWsClientContextType>(
  { connectionInfo: { status: "disconnected" } },
);

const wsUrl = new URL(process.env.LAYER_GRAPHQL_URI, window.location.origin);
wsUrl.protocol = window.location.protocol === "https:" ? "wss" : "ws";

export const GraphqlWsClientProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [client, setClient] = useState<Client>(null);
  const [connectionInfo, setConnectionInfo] =
    useState<GraphqlWsConnectionInfo>(null);

  return (
    <GraphqlWsClientContext.Provider
      value={{ client, connectionInfo: connectionInfo }}
    >
      <GraphqlWsClient
        url={wsUrl.toString()}
        keepAliveIntervalSeconds={10_000}
        keepAliveTimeoutSeconds={5_000}
        setConnectionInfo={setConnectionInfo}
        onClient={(client) => setClient(client)}
      />
      {children}
    </GraphqlWsClientContext.Provider>
  );
};

export const useWebSocketConnectionInfo = (): GraphqlWsConnectionInfo => {
  const { connectionInfo } = useContext(GraphqlWsClientContext);
  return connectionInfo;
};

export interface GraphqlWsClientProps {
  url: string;
  keepAliveIntervalSeconds: number;
  keepAliveTimeoutSeconds: number;
  onClient: (client: Client) => void;
  setConnectionInfo: Dispatch<SetStateAction<GraphqlWsConnectionInfo>>;
}

export const GraphqlWsClient = ({
  url,
  keepAliveIntervalSeconds,
  keepAliveTimeoutSeconds,
  onClient,
  setConnectionInfo,
}: GraphqlWsClientProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const client = useRef(null);

  useEffect(() => {
    const keepAliveState: { pingSentAt?: number; timeoutHandle?: any } = {};
    const wsClient = createWSClient({
      url,
      lazy: false,
      connectionParams: async () => ({
        Authorization: await getAccessTokenSilently(),
      }),
      shouldRetry: () => true,
      keepAlive: keepAliveIntervalSeconds, // ping server every 10 seconds
      on: {
        connected: () => {
          setConnectionInfo((prev) => {
            return {
              ...prev,
              status: "connected",
            };
          });
        },
        connecting: () => {
          setConnectionInfo((prev) => {
            return {
              ...prev,
              status: "connecting",
            };
          });
        },
        closed: () => {
          setConnectionInfo((prev) => {
            return {
              ...prev,
              status: "disconnected",
            };
          });
        },
        error: () => {
          setConnectionInfo((prev) => {
            return {
              ...prev,
              status: "error",
            };
          });
        },
        ping: (received) => {
          if (!received /* sent */) {
            keepAliveState.pingSentAt = Date.now();
            keepAliveState.timeoutHandle = setTimeout(() => {
              wsClient.terminate();
            }, keepAliveTimeoutSeconds);
          }
        },
        pong: (received) => {
          if (received) {
            setConnectionInfo((prev) => {
              return {
                ...prev,
                latencyMs: Date.now() - keepAliveState.pingSentAt,
              };
            });
            clearTimeout(keepAliveState.timeoutHandle);
          }
        },
      },
    });
    client.current = wsClient;
    onClient(client.current);
    return () => {
      wsClient.dispose();
    };
  }, [url, keepAliveIntervalSeconds, keepAliveTimeoutSeconds]);

  return null;
};
