import { Node } from "../components/graphql/schema.graphql";

export interface PageInfo {
  endCursor?: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string;
  totalCount?: number;
}

export type RelayEdge<N extends Node> = {
  node?: N;
  cursor: string;
};

export type RelayConnection<N extends Node> = {
  edges?: RelayEdge<N>[];
  pageInfo?: PageInfo;
};

export const getConnectionEdges = (connection: any) =>
  connection?.edges?.filter((e) => e != null) || [];

export const getConnectionNodes = (connection: any) =>
  getConnectionEdges(connection)
    .map((e) => e.node)
    .filter((n) => n != null) || [];

export function getConnectionEdgesTyped<N extends Node>(
  connection: RelayConnection<N>,
) {
  return connection?.edges?.filter((e) => e != null) || [];
}

export function getConnectionNodesTyped<N extends Node>(
  connection: RelayConnection<N>,
) {
  return (
    getConnectionEdgesTyped(connection)
      .map((e) => e.node)
      .filter((n) => n != null) || []
  );
}
