import { UpdatesConfig } from "@urql/exchange-graphcache";

import { DeleteStyleInput } from "./schema.graphql";

const updatesConfig: Partial<UpdatesConfig> = {
  Mutation: {
    deleteStyle: (result, _args, cache) => {
      const { input } = _args;
      const styleInput = input as DeleteStyleInput;

      if (styleInput.styleId) {
        cache.invalidate({ __typename: "Style", id: styleInput.styleId });
      }
    },
  },
  Query: {},
};

export default updatesConfig;
