import React, { useCallback, useRef, useState } from "react";

import { MenuProps } from "..";

export interface MenuState {
  anchorEl: HTMLElement | null;
  setAnchorEl: (element: HTMLElement | null) => void;
  open: boolean;
  startOpen: boolean;
  onClickAnchor: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseEnterAnchor: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeaveAnchor: (event: React.MouseEvent<HTMLElement>) => void;
  onFocusAnchor: (event: React.FocusEvent<HTMLElement>) => void;
  onMouseEnterMenu: () => void;
  onMouseLeaveMenu: () => void;
  onClose: () => void;
  useDefaultOrigins?: boolean;
  childItems: MenuState[];
}

export interface UseMenuStateProps {
  parentMenuState?: MenuState;
  startOpen?: boolean;
  openOnHover?: boolean;
  openOnClick?: boolean;
  openOnFocus?: boolean;
  closeOnLeave?: boolean;
  delay?: number;
  useDefaultOrigins?: boolean;
}

export function useMenuState({
  parentMenuState,
  startOpen = false,
  openOnClick = true,
  openOnHover = false,
  openOnFocus = false,
  closeOnLeave = false,
  delay = 0,
  useDefaultOrigins = false,
}: UseMenuStateProps = {}) {
  const childItems: MenuState[] = [];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const menuEnteredRef = useRef(false);

  const onClose = useCallback(() => {
    setAnchorEl(null);
    childItems.forEach((child) => child.onClose());
  }, [setAnchorEl, childItems]);

  const onOpen = useCallback(
    (el: HTMLElement, toggle?: boolean) => {
      childItems.forEach((child) => child.onClose());
      parentMenuState?.childItems
        ?.filter((child) => child.anchorEl !== el)
        ?.forEach((child) => child.onClose());
      setAnchorEl((anchorEl) => (anchorEl && toggle ? null : el));
    },
    [setAnchorEl, childItems, parentMenuState],
  );

  const onClickAnchor = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (openOnClick) {
        onOpen(event.currentTarget, true);
      }
    },
    [onOpen, openOnClick],
  );

  const onMouseEnterAnchor = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (openOnHover) {
        const anchor = event.currentTarget;
        timeoutRef.current = setTimeout(() => {
          onOpen(anchor);
        }, delay);
      }
    },
    [onOpen, openOnHover, delay, timeoutRef],
  );

  const onMouseLeaveAnchor = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, [timeoutRef]);

  const onMouseEnterMenu = useCallback(() => {
    menuEnteredRef.current = true;
  }, [menuEnteredRef]);

  const onMouseLeaveMenu = useCallback(() => {
    if (closeOnLeave && menuEnteredRef.current) {
      onClose();
    }
    menuEnteredRef.current = false;
  }, [onClose, closeOnLeave, menuEnteredRef]);

  const onFocusAnchor = useCallback(
    (event: React.FocusEvent<HTMLElement>) => {
      if (openOnFocus) {
        onOpen(event.currentTarget);
      }
    },
    [onOpen, openOnFocus],
  );

  const state = {
    anchorEl,
    setAnchorEl,
    open,
    startOpen,
    onClickAnchor,
    onMouseEnterAnchor,
    onMouseLeaveAnchor,
    onFocusAnchor,
    onMouseEnterMenu,
    onMouseLeaveMenu,
    onOpen,
    onClose,
    useDefaultOrigins,
    childItems,
  };

  if (parentMenuState) {
    parentMenuState.childItems.push(state);
  }

  return state;
}

export function bindMenuTrigger(state: MenuState) {
  return {
    onClick: state.onClickAnchor,
    onMouseEnter: state.onMouseEnterAnchor,
    onMouseLeave: state.onMouseLeaveAnchor,
    onFocus: state.onFocusAnchor,
    ...(state.startOpen && { ref: state.setAnchorEl }),
  };
}

export function bindMenu(state: MenuState) {
  return {
    anchorEl: state.anchorEl,
    open: state.open,
    onClose: state.onClose,
    onMouseEnter: state.onMouseEnterMenu,
    onMouseLeave: state.onMouseLeaveMenu,
    ...(state.useDefaultOrigins && {
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      } as MenuProps["anchorOrigin"],
      transformOrigin: {
        horizontal: "left",
        vertical: 6, // set this to 6px to match the current theme's margins per MenuItem
      } as MenuProps["transformOrigin"],
    }),
  };
}
