import { faSquareDashed as faSquareDashedRegular } from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowUpRight as faArrowUpRightSolid,
  faPalette as faPaletteSolid,
  faSquareDashed as faSquareDashedSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { CircularProgress, SxProps } from "@mui/material";

import { Box, Icon, IconProps } from "..";

interface UpscaleIconProps extends Omit<IconProps, "icon"> {
  variant?: "simple" | "creative";
  isLoading?: boolean;
}

export function UpscaleIcon({
  variant = "simple",
  isLoading,
  ...rest
}: UpscaleIconProps) {
  const baseIcon =
    variant === "simple" ? faSquareDashedSolid : faSquareDashedRegular;
  const actionIcon =
    variant === "simple" ? faArrowUpRightSolid : faPaletteSolid;

  if (isLoading) {
    return <CircularProgress color="inherit" size="1em" />;
  }

  const iconBoxProps: SxProps = {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,
    width: "1em",
    height: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box
      component={"span"}
      sx={{
        position: "relative",
        width: "1em",
        height: "1em",
        "& .base-icon": {
          opacity: 1,
        },
        ":hover .base-icon": {
          opacity: 0,
        },
        "& .action-icon": {
          opacity: 1,
          fontSize: variant === "simple" ? "0.75em" : "0.625em",
          marginTop: variant === "simple" ? "0.15em" : "0.275em",
          marginLeft: variant === "simple" ? "0.15em" : "0.275em",
        },
      }}
    >
      <Box component={"span"} className="base-icon" sx={iconBoxProps}>
        <Icon {...rest} icon={baseIcon} size="1x" />
      </Box>
      <Box component={"span"} className="action-icon" sx={iconBoxProps}>
        <Icon {...rest} icon={actionIcon} size="1x" />
      </Box>
    </Box>
  );
}
