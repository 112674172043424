import {
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  styled,
} from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { forwardRef } from "react";

export interface MenuProps extends MuiMenuProps {
  size?: "small" | "medium" | "large";
}

const extraProps: PropertyKey[] = ["size"];

const StyledMenu = styled(MuiMenu, {
  shouldForwardProp: (prop) => !extraProps.includes(prop),
})<MenuProps>(({ theme, size }) => {
  const { typography } = theme;

  const styles: CSSProperties = {};

  if (size === "small") {
    Object.assign(styles, {
      fontSize: typography.mini.fontSize,
      lineHeight: typography.mini.lineHeight,
    });
  } else if (size === "large") {
    Object.assign(styles, {
      fontSize: typography.regular.fontSize,
      lineHeight: typography.regular.lineHeight,
    });
  } else {
    Object.assign(styles, {
      fontSize: typography.small.fontSize,
      lineHeight: typography.small.lineHeight,
    });
  }
  return styles;
});

export const Menu = forwardRef<HTMLDivElement, MenuProps>(
  (props: MenuProps, ref) => {
    return <StyledMenu {...props} ref={ref} />;
  },
);
