import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mergeDeepRight, omit } from "ramda";

import { TabType } from "../../features/forge/forge-session/ForgeSessionInferences";
import {
  InferenceForm,
  InferenceParameters,
  StyleCapabilities,
} from "../../types";
import { roundTo } from "../../utils/numbers";
import {
  GenerationType,
  GuidanceFileType,
  InferenceQuality,
} from "../graphql/schema.graphql";
import { ForgeIcon } from "../ui-v2/icons";
import { InferenceStyle, InferenceType } from "./module";

/**
 * Convert guidance scale to a prompt strength value between 0 and 100. High prompt strength means
 * the image generation will more closely follow the prompt. Low prompt strength means the image will
 * be more creative.
 *
 * - Guidance scale of 2 is prompt strength 100
 * - Guidance scale of 4 is prompt strength 50
 * - Guidance scale of 6 is prompt strength 0
 *
 * @param scale guidance scale
 *
 * @returns prompt strength, between 0 and 100
 */
export function guidanceScaleToPromptStrength(scale: number) {
  return Math.max(Math.min(roundTo(100 - (scale - 2) * 25, 1), 100), 0);
}

/**
 * Convert prompt strength to a guidance scale value between 2 and 6.
 *
 * @param strength
 * @returns guidance scale, between 2 and 6
 */
export function guidanceScaleFromPromptStrength(strength: number) {
  return Math.max(Math.min(roundTo((100 - strength) / 25 + 2, 0.05), 6), 2);
}

export function imageWeightToSimilaritySlider(scale: number) {
  return Math.round(scale * 100);
}

export function imageWeightFromSimilaritySlider(slider: number) {
  return Math.round((slider / 100) * 20) / 20;
}

export function pixelDensityFromSlider(slider: number) {
  return Math.round(Math.min(Math.max(slider, 1), 4) / 0.5) * 0.5;
}

export function pixelDensityToSlider(pixelDensity: number) {
  if (!pixelDensity) return 1;
  return Math.min(Math.max(pixelDensity, 1), 4);
}

// TODO: Either remove this function or merge it in with formatFormValuesForAPI once we have the new inference form up and running
export function getInferenceParametersFromForm({
  inferenceForm,
  omitParameters = [],
  extraParameters = {},
  inferenceType,
}: {
  inferenceForm: InferenceForm;
  omitParameters?: string[];
  extraParameters?: DeepPartial<InferenceParameters>;
  inferenceType?: InferenceType;
}) {
  const allParameters = mergeDeepRight(
    { ...inferenceForm.parameters },
    extraParameters || {},
  );

  const parameters = omit(
    [
      "scheduler",
      "unenhancedPrompt",
      ...(omitParameters as unknown as Array<keyof InferenceParameters>),
    ] as const,
    allParameters,
  ) as InferenceParameters;

  parameters.files = parameters.files?.map((image) => {
    let weight = image.weight;
    if (inferenceType === "outpainting") {
      weight = 1;
    }
    return {
      ...omit(["selection", "openDropdownOnAttach", "width", "height"], image),
      weight,
    };
  });

  if (parameters.generationType === "UPSCALE") {
    parameters.batchSize = 1;
  }

  if (parameters.generationType === "ROTATE") {
    parameters.batchSize = 1;
    parameters.styles = [];
  }

  return parameters;
}

export function confirmInferenceParameters({
  inferenceForm,
  prevInferenceForm,
  activeStyle,
}: {
  inferenceForm: DeepPartial<InferenceForm>;
  prevInferenceForm?: InferenceForm;
  activeStyle?: InferenceStyle;
}) {
  const oldFileUrl = prevInferenceForm?.parameters?.files?.filter?.(
    (f) => !f.selection,
  )?.[0]?.url;
  const newFilesUrl = inferenceForm.parameters.files?.filter?.(
    (f) => !f.selection,
  )?.[0]?.url;
  const hasSelection = inferenceForm.parameters.files?.some((f) => f.selection);

  const hasNewFile = oldFileUrl !== newFilesUrl;
  const hasFiles = !!inferenceForm.parameters.files?.length;
  if (hasNewFile) {
    inferenceForm.customSize = false;
  }

  if (
    prevInferenceForm?.parameters?.styles !== inferenceForm.parameters.styles
  ) {
    inferenceForm.parameters.numInferenceSteps = null;
  }

  if (
    !inferenceForm.parameters.styles ||
    !inferenceForm.parameters.styles.some(
      (style) => style.id === activeStyle?.id,
    )
  ) {
    return; // activeStyle does not match the form, so we should not use its capabilities
  }

  if (!hasFiles && activeStyle.capabilities?.textToImageSizes?.[0]) {
    // then text to image, so set the default size
    const isSizeValid = activeStyle.capabilities.textToImageSizes.some(
      ([w, h]) =>
        w === inferenceForm.parameters.width &&
        h === inferenceForm.parameters.height,
    );
    if (!isSizeValid) {
      inferenceForm.parameters.width =
        activeStyle.capabilities.textToImageSizes[0][0];
      inferenceForm.parameters.height =
        activeStyle.capabilities.textToImageSizes[0][1];
    }
  } else if (
    !inferenceForm.customSize &&
    activeStyle.capabilities?.customSizeTarget &&
    !hasSelection
  ) {
    const file = inferenceForm.parameters.files[0];
    if (file.width && file.height) {
      const aspectRatio = file.width / file.height;
      if (aspectRatio > 1) {
        inferenceForm.parameters.width =
          activeStyle.capabilities.customSizeTarget;
        inferenceForm.parameters.height = Math.round(
          activeStyle.capabilities.customSizeTarget / aspectRatio,
        );
      } else {
        inferenceForm.parameters.height =
          activeStyle.capabilities.customSizeTarget;
        inferenceForm.parameters.width = Math.round(
          activeStyle.capabilities.customSizeTarget * aspectRatio,
        );
      }
    }
  }

  // set default numInferenceSteps
  if (
    !inferenceForm.parameters.numInferenceSteps &&
    activeStyle?.defaultNumInferenceSteps
  ) {
    inferenceForm.parameters.numInferenceSteps =
      activeStyle.defaultNumInferenceSteps;
  }
}

export function getForgeUrl({
  workspaceName,
  inputs,
  addStyle = false,
  disableTour = false,
}: {
  workspaceName: string;
  inputs?: DeepPartial<InferenceForm>;
  addStyle?: boolean;
  disableTour?: boolean;
}) {
  const params = new URLSearchParams();
  if (inputs) {
    params.append("inputs", JSON.stringify(inputs));
  }
  if (addStyle) {
    params.append("addStyle", "true");
  }
  if (disableTour) {
    params.append("disableTour", "true");
  }
  return `/${workspaceName}/session/new?${params.toString()}`;
}

export function getForgeSessionUrl({
  workspaceName,
  sessionId,
  inputs,
  addStyle = false,
  disableTour = false,
  tab,
}: {
  workspaceName: string;
  sessionId: string;
  inputs?: DeepPartial<InferenceForm>;
  addStyle?: boolean;
  disableTour?: boolean;
  tab?: TabType;
}) {
  const params = new URLSearchParams();
  if (inputs) {
    params.append("inputs", JSON.stringify(inputs));
  }
  if (addStyle) {
    params.append("addStyle", "true");
  }
  if (disableTour) {
    params.append("disableTour", "true");
  }
  if (tab) {
    params.append("tab", tab);
  }
  return `/${workspaceName}/session/${sessionId}?${params.toString()}`;
}

export function isImageTypeValid(
  imageType: GuidanceFileType,
  capabilities: StyleCapabilities,
  allowedImageTypes?: GuidanceFileType[],
  params?: InferenceParameters,
  currentImageType?: GuidanceFileType,
) {
  if (params && currentImageType && currentImageType === imageType) {
    return true;
  }
  if (
    params &&
    params.files &&
    params.files.some((paramImage) => paramImage.type === imageType)
  ) {
    return false;
  }
  if (allowedImageTypes && !allowedImageTypes.includes(imageType)) {
    return false;
  }
  return capabilities?.imageTypes?.includes(imageType);
}

export const generationTypes = [
  {
    type: "CREATE",
    actionName: "Forge",
    tooltip: "Generate images inside the selected area",
    icon: (
      <ForgeIcon
        weight="solid"
        className="cursor-pointer relative z-[1] pointer-events-none"
      />
    ),
  },
  {
    type: "REFILL",
    actionName: "Refill",
    tooltip: "Refill transparent pixels",
    icon: (
      <FontAwesomeIcon
        icon={solid("diamond-half-stroke")}
        className="cursor-pointer relative z-[1] pointer-events-none"
      />
    ),
  },
  {
    type: "EDIT",
    actionName: "Edit",
    tooltip: "Edit the image using a text prompt",
    icon: (
      <FontAwesomeIcon
        icon={solid("pencil")}
        className="cursor-pointer relative z-[1] pointer-events-none"
      />
    ),
  },
];

export const generationTypesMap = generationTypes.reduce(
  (acc, type) => ({ ...acc, [type.type]: type }),
  {},
) as Record<GenerationType, (typeof generationTypes)[0]>;

export const inferenceQualities = [
  {
    type: "HIGH" as InferenceQuality,
    actionName: "High",
    tooltip: "Higher quality but slower generation",
    icon: (
      <FontAwesomeIcon
        icon={solid("gauge-simple-min")}
        className="cursor-pointer relative z-[1] pointer-events-none"
      />
    ),
  },
  {
    type: "MEDIUM" as InferenceQuality,
    actionName: "Medium",
    tooltip: "Balanced quality and speed",
    icon: (
      <FontAwesomeIcon
        icon={solid("gauge-simple")}
        className="cursor-pointer relative z-[1] pointer-events-none"
      />
    ),
  },
  {
    type: "LOW" as InferenceQuality,
    actionName: "Low",
    tooltip: "Lower quality but faster generation",
    icon: (
      <FontAwesomeIcon
        icon={solid("gauge-simple-max")}
        className="cursor-pointer relative z-[1] pointer-events-none"
      />
    ),
  },
];

export const inferenceQualitiesMap = inferenceQualities.reduce(
  (acc, type) => ({ ...acc, [type.type]: type }),
  {},
) as Record<InferenceQuality, (typeof inferenceQualities)[0]>;
