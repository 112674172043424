import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

import { Icon, IconButton } from "../../../";

interface StyleTileFooterProps {
  styleName: string;
  workspaceName: string;
  workspaceLogoSrc?: string;
  onActionMenuOpen: (event?: React.MouseEvent) => void;
}

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(2),
}));

const WorkspaceLogo = styled("img")({
  height: 16,
  width: 16,
  borderRadius: 4,
});

const StyleName = styled(Typography)({
  width: "100%",
});

const FooterLeftsideContainer = styled(Stack)({
  width: "100%",
  overflow: "hidden",
});

export const StyleTileFooter = (props: StyleTileFooterProps) => {
  const { styleName , workspaceName, workspaceLogoSrc, onActionMenuOpen } = props; // eslint-disable-line
  return (
    <Container>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <FooterLeftsideContainer>
          <StyleName noWrap>{styleName || "No title"}</StyleName>
          <Stack direction="row" spacing={1}>
            {workspaceLogoSrc && (
              <WorkspaceLogo src={workspaceLogoSrc} alt={workspaceName} />
            )}
            <Typography variant="mini" color="secondary.300" noWrap>
              {workspaceName}
            </Typography>
          </Stack>
        </FooterLeftsideContainer>
        <IconButton
          aria-label="Open style options"
          variant="quaternary"
          onClick={onActionMenuOpen}
        >
          <Icon size="small" icon={solid("ellipsis-vertical")} />
        </IconButton>
      </Stack>
    </Container>
  );
};
