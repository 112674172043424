import {
  brands,
  icon,
  regular,
  solid,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { SxProps, TooltipProps } from "@mui/material";
import { useTheme } from "@mui/system";
import { forwardRef, useMemo } from "react";

import { useNavigationStore } from "../../../../components/nav/state";
import {
  Avatar,
  AvatarGroup,
  bindMenu,
  bindMenuTrigger,
  Box,
  Button,
  Divider,
  Icon,
  IconMenuItem,
  Link,
  Menu,
  Stack,
  Tooltip,
  Typography,
  useMenuState,
} from "../../../../components/ui-v2";
import { discordUrl, supportUrl } from "../../../../utils/constants";
import { WorkspaceDropdownInfo } from "./types";
import { getWorkspaceDisplayName, getWorkspaceInitial } from "./utils";

interface WorkspaceDropdownProps {
  allWorkspaces: WorkspaceDropdownInfo[];
  currentWorkspaceId: string;
  personalWorkspaceId: string;
  userEmail: string;
  userFullName?: string;
  userProfilePictureUrl?: string;
  handleIntercomMessage?: () => void;
  startOpen?: boolean;
}

function getTierName(tier: WorkspaceDropdownInfo["planTier"]) {
  switch (tier) {
    case "ENTERPRISE":
      return "Enterprise";
    case "FREE":
      return "Free";
    case "PRO":
      return "Pro";
    case "STUDIO":
      return "Studio";
    default:
      return "";
  }
}

interface WorkspaceAvatarProps {
  workspace: WorkspaceDropdownInfo;
  sx: SxProps;
  onClick?: (e?: any) => void;
  tooltipPlacement?: TooltipProps["placement"];
  disableTooltip?: TooltipProps["disableHoverListener"];
}

const WorkspaceAvatar = forwardRef<HTMLAnchorElement, WorkspaceAvatarProps>(
  (
    {
      workspace,
      sx,
      onClick,
      tooltipPlacement = "top",
      disableTooltip = false,
    }: WorkspaceAvatarProps,
    ref,
  ) => {
    return (
      <Tooltip
        title={workspace.displayName}
        placement={tooltipPlacement}
        arrow
        enterDelay={300}
        disableHoverListener={disableTooltip}
        disableFocusListener={disableTooltip}
      >
        <Avatar
          ref={ref}
          component={Link}
          href={`/${workspace.name}`}
          onClick={onClick}
          sx={{
            color: "primary.600",
            bgcolor: "primary.200",
            fontWeight: "bold",
            borderRadius: 2,

            "&&&": {
              border: "none",
            },
            ...sx,
          }}
          src={workspace.profilePicture}
          alt={getWorkspaceDisplayName(workspace)}
        >
          {getWorkspaceInitial(workspace)}
        </Avatar>
      </Tooltip>
    );
  },
);

export const WorkspaceDropdown = ({
  allWorkspaces,
  currentWorkspaceId,
  personalWorkspaceId,
  userEmail,
  userFullName,
  userProfilePictureUrl,
  handleIntercomMessage,
  startOpen = false,
}: WorkspaceDropdownProps) => {
  const theme = useTheme();
  const { expanded } = useNavigationStore();
  const { palette } = theme;
  const workspaces = useMemo(
    () =>
      allWorkspaces.reduce(
        (acc, workspace) => {
          if (workspace.id === currentWorkspaceId) {
            acc.current = workspace;
          } else {
            acc.other.push(workspace);
          }

          if (workspace.id === personalWorkspaceId) {
            acc.personal = workspace;
          } else {
            acc.team.push(workspace);
          }
          return acc;
        },
        {
          current: null as WorkspaceDropdownInfo | null,
          personal: null as WorkspaceDropdownInfo | null,
          team: [] as WorkspaceDropdownInfo[],
          other: [] as WorkspaceDropdownInfo[],
        },
      ),
    [allWorkspaces, currentWorkspaceId, personalWorkspaceId],
  );

  const isPersonalWorkspace = currentWorkspaceId === personalWorkspaceId;

  const mainMenuState = useMenuState({ startOpen });
  const workspacesMenuState = useMenuState({
    parentMenuState: mainMenuState,
  });
  const billingMenuState = useMenuState({
    parentMenuState: mainMenuState,
    openOnHover: true,
    delay: 1000,
    useDefaultOrigins: true,
  });

  const workspaceSubtitle: string[] = [];
  if (workspaces.current.planTier) {
    workspaceSubtitle.push(`${getTierName(workspaces.current.planTier)} Plan`);
  }
  workspaceSubtitle.push(
    isPersonalWorkspace ? "Personal Workspace" : "Team Workspace",
  );

  if (!workspaces.current) {
    return null;
  }

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        sx={{
          transition: "all 300ms ease-out",
          paddingLeft: expanded ? 0 : 0,
          paddingRight: 1,
          paddingTop: 1,
          paddingBottom: 1,
          fontWeight: 600,
          maxWidth: expanded ? "100%" : "28px",
          border: "none",
          justifyContent: "flex-start",
          backgroundColor: mainMenuState.open
            ? theme.palette.secondary[900]
            : "transparent",
          boxShadow: mainMenuState.open
            ? `inset 0 0 0 0.5px ${theme.palette.secondary[700]}`
            : "none",

          "&:hover": {
            boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["700/50"]}`,
          },

          "& .MuiButton-startIcon": {
            marginLeft: "-5px",
            transform: expanded ? "none" : "translateX(-1px)",
            transition: "all 300ms ease-out",
          },

          "& .MuiButton-icon": {
            marginLeft: expanded ? 0 : "1px",
            marginRight: "5px",
          },
        }}
        startIcon={
          <Box
            sx={{
              height: theme.spacing(7),
              width: theme.spacing(7),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <WorkspaceAvatar
              workspace={workspaces.current}
              sx={{ width: expanded ? 20 : 24, height: expanded ? 20 : 24 }}
              disableTooltip={expanded}
              tooltipPlacement="right"
            />
          </Box>
        }
        endIcon={
          expanded && (
            <span
              style={{
                fontSize: "0.85em",
                color: palette.secondary[300],
                marginLeft: 8,
              }}
            >
              <Icon
                icon={icon({
                  name: "angle-down",
                  family: "sharp",
                  style: "solid",
                })}
              />
            </span>
          )
        }
        {...bindMenuTrigger(mainMenuState)}
      >
        {expanded && (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              transition: "all 300ms ease-out",
              maxWidth: expanded ? 200 : 0,
            }}
          >
            {getWorkspaceDisplayName(workspaces.current)}
          </span>
        )}
      </Button>

      <Menu
        {...bindMenu(mainMenuState)}
        MenuListProps={{
          component: "nav",
        }}
        anchorOrigin={{ vertical: 30, horizontal: 0 }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          "& .MuiPaper-root": {
            width: "208px",
            marginLeft: -2,
          },
        }}
      >
        <Box sx={{ backgroundColor: palette.secondary["700/25"] }}>
          <Stack
            direction="column"
            spacing={2.5}
            sx={{
              paddingTop: 3.5,
              paddingLeft: 3.5,
              paddingRight: 3.5,
              paddingBottom: 3,
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignItems="start"
              justifyContent="space-between"
            >
              <WorkspaceAvatar
                workspace={workspaces.current}
                sx={{
                  width: 72,
                  height: 72,
                  fontSize: 32,
                  pointerEvents: "none",
                }}
                onClick={mainMenuState.onClose}
              />
              <AvatarGroup
                max={4}
                spacing={-3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                renderSurplus={() => (
                  <Tooltip title="Switch workspace" placement="top">
                    <Icon
                      icon={icon({
                        name: "ellipsis",
                        family: "sharp",
                        style: "regular",
                      })}
                    />
                  </Tooltip>
                )}
                componentsProps={{
                  additionalAvatar: {
                    ...bindMenuTrigger(workspacesMenuState),
                    sx: {
                      cursor: "pointer",
                      fontSize: "12px",
                      width: "20px",
                      height: "20px",
                      color: "secondary.200",
                      backgroundColor: "transparent",
                      borderSize: 4,
                      borderRadius: 2,

                      "&&&": {
                        borderColor: workspacesMenuState.open
                          ? "secondary.400"
                          : "secondary.500",
                        color: workspacesMenuState.open
                          ? "secondary.50"
                          : "secondary.200",
                        backgroundColor: workspacesMenuState.open
                          ? "secondary.600"
                          : "transparent",
                      },
                    },
                  },
                }}
              >
                {workspaces.other.map((workspace) => (
                  <WorkspaceAvatar
                    key={workspace.id}
                    workspace={workspace}
                    sx={{ width: "20px", height: "20px", fontSize: "12px" }}
                    onClick={mainMenuState.onClose}
                  />
                ))}
              </AvatarGroup>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                fontWeight={600}
                variant="regular"
                lineHeight={1}
              >
                {getWorkspaceDisplayName(workspaces.current)}
              </Typography>
              <Typography variant="mini" color="text.secondary">
                {workspaceSubtitle.join(" · ")}
              </Typography>
            </Stack>
          </Stack>
          {workspaces.current.myPermissions?.includes("workspace:settings") && (
            <IconMenuItem
              component={Link}
              href={`/${workspaces.current.name}/settings/general`}
              onClick={mainMenuState.onClose}
              leftIcon={<Icon icon={solid("square-sliders-vertical")} />}
              label="General Settings"
              sx={{
                color: "text.primary",
                paddingRight: theme.spacing(8),
                fontWeight: 500,
              }}
            />
          )}
          {workspaces.current.myPermissions?.includes("workspace:members") && (
            <IconMenuItem
              component={Link}
              href={`/${workspaces.current.name}/settings/members`}
              onClick={mainMenuState.onClose}
              leftIcon={<Icon icon={solid("square-user")} />}
              label="Manage Members"
              sx={{
                color: "text.primary",
                paddingRight: theme.spacing(8),
                fontWeight: 500,
              }}
            />
          )}
          {workspaces.current.myPermissions?.includes("workspace:billing") && (
            <IconMenuItem
              {...bindMenuTrigger(billingMenuState)}
              sx={{ color: "text.primary", fontWeight: 500 }}
              leftIcon={<Icon icon={solid("square-dollar")} />}
              rightIcon={<Icon icon={solid("caret-right")} />}
              label="Plans & Billing"
            />
          )}
          <Divider />
        </Box>
        {workspaces.current.myPermissions?.includes("workspace:settings") && (
          <Box>
            <IconMenuItem
              component={Link}
              href="/settings/tokens"
              leftIcon={<Icon icon={regular("share-nodes")} rotation={90} />}
              label="Manage Access Tokens"
              sx={{
                color: "text.secondary",
                paddingRight: theme.spacing(8),
                fontWeight: 500,
              }}
            />
            <Divider />
          </Box>
        )}
        <IconMenuItem
          component={Link}
          href={supportUrl}
          target="_blank"
          onClick={mainMenuState.onClose}
          leftIcon={<Icon icon={regular("circle-question")} />}
          label="Layer Docs & FAQs"
          sx={{
            color: "text.secondary",
            paddingRight: theme.spacing(8),
            fontWeight: 500,
          }}
        />
        <IconMenuItem
          leftIcon={<Icon icon={regular("message-lines")} />}
          onClick={() => {
            handleIntercomMessage();
            mainMenuState.onClose();
          }}
          label="Contact Support"
          sx={{
            color: "text.secondary",
            paddingRight: theme.spacing(8),
            fontWeight: 500,
          }}
        />
        <Divider />
        <IconMenuItem
          component={Link}
          href={discordUrl}
          target="_blank"
          onClick={mainMenuState.onClose}
          leftIcon={<Icon icon={brands("discord")} />}
          label="Join our Discord"
          sx={{
            color: "text.secondary",
            paddingRight: theme.spacing(8),
            fontWeight: 500,
          }}
        />
        <Divider />
        <Box padding={2} marginBottom={1}>
          <Stack direction="column" spacing={2}>
            <Typography variant="mini" color="text.secondary">
              Signed in as
            </Typography>
            <Stack direction="row" spacing={3} alignItems={"center"}>
              <Avatar
                sx={{ width: 24, height: 24 }}
                src={userProfilePictureUrl}
                alt={userFullName || userEmail}
              />
              <Stack direction="column">
                <Typography
                  variant="small"
                  color="text.primary"
                  fontWeight="bold"
                  sx={{ lineHeight: 1 }}
                >
                  {userFullName || userEmail}
                </Typography>
                <Typography variant="mini" color="text.secondary">
                  {userEmail}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <IconMenuItem
          component={Link}
          href={"/logout"}
          leftIcon={<Icon icon={regular("arrow-right-from-bracket")} />}
          label="Log Out"
          sx={{
            color: "text.secondary",
            paddingRight: theme.spacing(8),
            fontWeight: 500,
          }}
        />
      </Menu>
      <Menu
        {...bindMenu(billingMenuState)}
        size="small"
        slotProps={{ paper: { elevation: 0, variant: "outlined" } }}
      >
        <IconMenuItem
          component={Link}
          href={`/${workspaces.current.name}/settings/plans`}
          onClick={mainMenuState.onClose}
          leftIcon={<Icon icon={regular("rectangle-pro")} />}
          label="Manage Plan"
          sx={{
            color: "text.primary",
            paddingRight: theme.spacing(8),
            fontWeight: 500,
          }}
        />
        <IconMenuItem
          component={Link}
          href={`/${workspaces.current.name}/settings/billing`}
          onClick={mainMenuState.onClose}
          leftIcon={<Icon icon={regular("credit-card-front")} />}
          label="Billing & Payment"
          sx={{
            color: "text.primary",
            paddingRight: theme.spacing(8),
            fontWeight: 500,
          }}
        />
      </Menu>
      <Menu
        {...bindMenu(workspacesMenuState)}
        size="small"
        sx={{ maxWidth: "300px" }}
        slotProps={{ paper: { elevation: 0, variant: "outlined" } }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: 16, horizontal: -18 }}
      >
        <Box padding={2}>
          <Typography variant="mini" color="text.secondary" fontWeight={600}>
            Switch Workspace
          </Typography>
        </Box>
        <Divider />
        {workspaces.personal && (
          <Box padding={2}>
            <Typography variant="micro" color="text.secondary" fontWeight={600}>
              Personal Workspace
            </Typography>
          </Box>
        )}
        {workspaces.personal && (
          <IconMenuItem
            component={Link}
            href={`/${workspaces.personal.name}`}
            onClick={mainMenuState.onClose}
            selected={workspaces.current.id === workspaces.personal.id}
            disabled={workspaces.current.id === workspaces.personal.id}
            leftIcon={
              <WorkspaceAvatar
                workspace={workspaces.personal}
                sx={{ width: 18, height: 18, fontSize: 12 }}
                onClick={mainMenuState.onClose}
              />
            }
            label={getWorkspaceDisplayName(workspaces.personal)}
            sx={{
              paddingRight: theme.spacing(8),
              fontWeight: 500,
            }}
          />
        )}
        {workspaces.team.length > 0 && (
          <Box padding={2}>
            <Typography variant="micro" color="text.secondary" fontWeight={600}>
              Team Workspaces
            </Typography>
          </Box>
        )}
        {workspaces.team.map((workspace) => (
          <IconMenuItem
            key={workspace.id}
            component={Link}
            href={`/${workspace.name}`}
            onClick={mainMenuState.onClose}
            selected={workspaces.current.id === workspace.id}
            disabled={workspaces.current.id === workspace.id}
            leftIcon={
              <WorkspaceAvatar
                workspace={workspace}
                sx={{ width: 18, height: 18, fontSize: 12 }}
                onClick={mainMenuState.onClose}
              />
            }
            label={getWorkspaceDisplayName(workspace)}
            sx={{
              paddingRight: theme.spacing(8),
              fontWeight: 500,
            }}
          />
        ))}
        <Divider />
        <IconMenuItem
          component={Link}
          href="/new"
          onClick={mainMenuState.onClose}
          leftIcon={<Icon icon={regular("plus")} />}
          label="Create New Workspace"
          sx={{
            height: "28px",
            color: "text.primary",
            paddingRight: theme.spacing(8),
            fontWeight: 500,
          }}
        />
        <IconMenuItem
          component={Link}
          href="/settings/workspaces"
          onClick={mainMenuState.onClose}
          leftIcon={<Icon icon={regular("plus")} />}
          label="Manage Memberships"
          sx={{
            height: "28px",
            color: "text.primary",
            paddingRight: theme.spacing(8),
            fontWeight: 500,
          }}
        />
      </Menu>
    </>
  );
};
