import { Tabs as MuiTabs, TabsOwnProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

interface TabsProps extends TabsOwnProps {
  underline?: boolean;
  size?: "medium" | "large";
}

const getCommonStyles = (theme, size) => {
  const { typography, spacing } = theme;

  return {
    width: "fit-content",

    "& .MuiTabs-flexContainer .MuiTab-root": {
      height: size === "large" ? spacing(9) : spacing(7),
      padding: size === "large" ? spacing(3) : spacing(1, 2),
      columnGap: "5px",
      fontSize: typography.small.fontSize,
      textTransform: "none",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "transparent",
      borderRadius: spacing(1),
    },
  };
};

const getUnderlineStyles = (theme) => {
  const { palette, spacing } = theme;

  return {
    backgroundColor: "transparent",
    "& .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root": {
      marginBottom: spacing(4),
      fontWeight: 500,
      color: palette.secondary[300],
      marginRight: spacing(2),
      "&:last-child": {
        marginRight: 0,
      },
      "&:hover": {
        backgroundColor: palette.secondary[900],
        borderColor: palette.secondary[700],
      },
      "&.Mui-selected": {
        backgroundColor: palette.secondary[800],
        borderColor: palette.secondary[600],
        color: palette.secondary[50],
      },
    },
    "& .MuiTabs-indicator": {
      height: "1px",
      backgroundColor: palette.secondary[500],
    },
  };
};

const getDefaultTabStyles = (theme) => {
  const { palette, spacing } = theme;

  return {
    borderRadius: spacing(1),
    "& .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root": {
      fontWeight: 400,
      color: palette.secondary[300],
      "&:last-child": {
        marginRight: 0,
      },
      "&.Mui-selected": {
        backgroundColor: palette.secondary[800],
        borderColor: palette.secondary["700/75"],
        color: palette.secondary[50],
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  };
};

const StyledTabs = styled(MuiTabs, {
  shouldForwardProp: (prop) => prop !== "underline" && prop !== "size",
})<TabsProps>(({ theme, underline = false, size = "medium" }) => {
  const commonStyles = getCommonStyles(theme, size);
  const tabStyles = underline
    ? getUnderlineStyles(theme)
    : getDefaultTabStyles(theme);
  return { ...commonStyles, ...tabStyles };
});

export const Tabs = React.forwardRef<HTMLDivElement, TabsProps>(
  ({ underline = false, size = "medium", ...rest }: TabsProps, ref) => {
    return <StyledTabs {...rest} ref={ref} underline={underline} size={size} />;
  },
);
