import {
  icon,
  regular,
  solid,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/system";
import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useBasicData } from "../../hooks";
import { getConnectionNodesTyped, RelayConnection } from "../../utils/relay";
import { truncate } from "../../utils/strings";
import {
  User,
  Workspace,
  WorkspaceMembership,
} from "../graphql/schema.graphql";
import { Box, Icon } from "../ui-v2";
import { ForgeIcon } from "../ui-v2/icons";
import { NavFooter } from "./components/NavFooter/NavFooter";
import DefaultProfileImage from "./components/NavHeader/components/DefaultProfileImage/DefaultProfileImage";
import { NavHeader } from "./components/NavHeader/NavHeader";
import { NavItemsContainer } from "./components/NavItemsContainer/NavItemsContainer";
import { useNavigationStore } from "./state";

const SIDEBAR_WIDTH = 220;
const SIDEBAR_COLLAPSED_WIDTH = 56;

const NON_TEAM_WORKSPACES = ["FREE", "PRO"];

interface NavProps {
  isDefaultCollapsed: boolean;
}

type AppMenuWorkspace = Pick<
  Workspace,
  "__typename" | "id" | "name" | "profilePicture" | "myRole" | "myPermissions"
>;

type AppMenuMembership = Pick<WorkspaceMembership, "__typename" | "id"> & {
  workspace: AppMenuWorkspace;
};

type AppMenuUser = Pick<User, "__typename" | "profilePicture"> & {
  personalWorkspace?: AppMenuWorkspace;
  memberships: RelayConnection<AppMenuMembership>;
};

export type AppMenuItem = {
  id: string;
  name: string;
  route: string;
  icon: JSX.Element;
  activeIcon?: JSX.Element;
  colors?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  disabled?: boolean;
  dataCy?: string;
};

const NavContainer = styled("nav", {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ expanded }) => ({
  position: "fixed",
  width: expanded ? SIDEBAR_WIDTH : SIDEBAR_COLLAPSED_WIDTH,
  height: "100%",
  transition: "width 300ms ease-out",
  zIndex: 200,
}));

const ContentSpacer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ expanded }) => ({
  width: expanded ? SIDEBAR_WIDTH : SIDEBAR_COLLAPSED_WIDTH,
  flexShrink: 0,
  transition: "width 300ms ease-out",
}));

const ContentContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  height: "100%",
  width: "100%",
  transition: "padding-left 300ms ease-out, padding-right 300ms ease-out",
  overflowY: "auto",
  overflowX: "clip",
  color: theme.palette.secondary[300],
  display: "flex",
  flexDirection: "column",
  paddingLeft: expanded ? 12 : 14,
  paddingRight: expanded ? 12 : 14,
}));

export const Nav = (props: NavProps) => {
  const { isDefaultCollapsed } = props;
  const { workspace: workspaceName } = useParams();
  const location = useLocation(); // Add this to fix the location issue
  const { user, workspace, workspaceError } = useBasicData();
  const { expanded, userToggled, setExpanded } = useNavigationStore();

  useEffect(() => {
    const updateSidebarState = () => {
      const { expanded, userToggled } = useNavigationStore.getState();

      if (userToggled) {
        setExpanded(expanded);
      } else {
        setExpanded(!isDefaultCollapsed);
      }
    };

    updateSidebarState();
  }, [location.pathname, isDefaultCollapsed, userToggled]);

  const getAppMenuItems = useCallback(
    (workspace: AppMenuWorkspace, me: AppMenuUser) => {
      if (!workspace || !workspace.myRole) {
        if (me && me.personalWorkspace) {
          return getConnectionNodesTyped(me.memberships)
            ?.filter((w) => w.workspace?.id !== me.personalWorkspace?.id)
            ?.sort((a, b) => a.workspace.name.localeCompare(b.workspace.name))
            ?.map((membership) => ({
              id: membership.workspace.id,
              name: truncate(membership.workspace.name, 18),
              route: `/${membership.workspace.name}`,
              icon: membership.workspace.profilePicture ? (
                <img
                  src={membership.workspace.profilePicture}
                  className="w-[20px] rounded bg-layer-700"
                />
              ) : (
                <DefaultProfileImage
                  workspaceName={membership.workspace.name}
                  className="text-xs w-[20px] rounded"
                />
              ),
              colors: "text-layer-600 bg-white/75",
              className: "mb-1",
              disabled: false,
              dataCy: "sidebar-workspace",
            }));
        }
        return [];
      } else {
        const items = [
          {
            id: "home",
            name: "Home",
            route: `/${workspace.name}`,
            icon: (
              <Icon
                size="medium"
                icon={icon({
                  name: "house-window",
                  family: "sharp",
                  style: "regular",
                })}
              />
            ),
            activeIcon: (
              <Icon
                size="medium"
                icon={icon({
                  name: "house-window",
                  family: "sharp",
                  style: "solid",
                })}
              />
            ),
            colors: "text-[inherit] bg-transparent",
            className: "mb-1",
            disabled: false,
          },
        ];
        if (workspace.myPermissions.includes("asset:view")) {
          items.push({
            id: "drive",
            name: "Drive",
            route: `/${workspace.name}/drive`,
            icon: (
              <Icon
                icon={icon({
                  name: "layer-group",
                  family: "sharp",
                  style: "regular",
                })}
              />
            ),
            activeIcon: (
              <Icon
                icon={icon({
                  name: "layer-group",
                  family: "sharp",
                  style: "solid",
                })}
              />
            ),
            colors: "text-[inherit] bg-transparent",
            className: "mb-1",
            disabled: false,
          });
        }
        items.push({
          id: "styles",
          name: "Styles",
          route: `/${workspace.name}/styles`,
          icon: <Icon icon={regular("palette")} />,
          activeIcon: <Icon icon={solid("palette")} />,
          colors: "text-[inherit] bg-transparent",
          className: "mb-1",
          disabled: false,
        });

        if (
          workspace.myPermissions.includes("inference:view_history") ||
          workspace.myPermissions.includes("style:view")
        ) {
          items.push({
            id: "prompts",
            name: "History",
            route: `/${workspace.name}/prompts`,
            icon: (
              <Icon
                icon={icon({
                  name: "clock-rotate-left",
                  family: "sharp",
                  style: "regular",
                })}
              />
            ),
            activeIcon: (
              <Icon
                icon={icon({
                  name: "clock-rotate-left",
                  family: "sharp",
                  style: "solid",
                })}
              />
            ),
            colors: "text-[inherit] bg-transparent",
            className: "mb-3",
            disabled: false,
          });
        }
        return items;
      }
    },
    [location.pathname],
  );

  const getCreateMenuItems = useCallback(
    (workspace) => {
      if (!workspace?.name) {
        return [];
      }

      return [
        {
          id: "forge",
          name: "Forge",
          route: `/${workspace.name}/session/new`,
          icon: <ForgeIcon weight="regular" />,
          activeIcon: <ForgeIcon weight="solid" />,
        },
        {
          id: "assets",
          name: "Canvas",
          route: `/${workspace.name}/assets/new`,
          icon: <Icon icon={regular("pencil")} />,
          activeIcon: <Icon icon={solid("pencil")} />,
        },
      ];
    },
    [location.pathname],
  );

  const appMenuItems = useMemo(
    () => getAppMenuItems(workspace, user),
    [workspaceName, user.id, workspace],
  );

  const createMenuItems = useMemo(
    () => getCreateMenuItems(workspace),
    [workspaceName, workspace],
  );

  if (!!workspaceName && !workspace && !workspaceError) {
    return null;
  }

  return (
    <>
      <ContentSpacer expanded={expanded} />
      <NavContainer expanded={expanded} data-cy="nav-sidebar">
        <ContentContainer expanded={expanded}>
          <NavHeader workspace={workspace} />
          <NavItemsContainer
            items={appMenuItems}
            createMenuItems={createMenuItems}
            isPersonalWorkspace={NON_TEAM_WORKSPACES.includes(
              workspace?.planTier,
            )}
          />
          <NavFooter />
        </ContentContainer>
      </NavContainer>
    </>
  );
};
