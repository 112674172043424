import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AnyVariables, UseMutationState, UseQueryState } from "urql";

import { isError, TypedObject } from "../utils/graphql";
import useNextParam from "./useNextParam";
import useShowSnackbar from "./useShowSnackbar";

type ErrorWithCode = {
  code: string;
  message: string;
};

type UseUrqlErrorHandlerArgs<
  Data = any,
  Variables extends AnyVariables = AnyVariables,
> = {
  state: UseQueryState<Data, Variables> | UseMutationState<Data, Variables>;
  extractResponseObjects: (data: Data) => TypedObject[];
  redirectOnError?: boolean;
  showSnackbarOnError?: boolean;
  getErrorMessage?: (err: ErrorWithCode) => string | null;
  onError?: (err: ErrorWithCode) => void;
};

type UseUrqlErrorHandlerResponse = {
  error?: ErrorWithCode;
};

function defaultGetErrorMessage({ message }: ErrorWithCode) {
  return message;
}

export function useUrqlErrorHandler<
  Data = any,
  Variables extends AnyVariables = AnyVariables,
>({
  state,
  extractResponseObjects,
  redirectOnError,
  showSnackbarOnError,
  getErrorMessage = defaultGetErrorMessage,
  onError,
}: UseUrqlErrorHandlerArgs<Data, Variables>): UseUrqlErrorHandlerResponse {
  const nextParam = useNextParam();
  const { showError } = useShowSnackbar();
  const navigate = useNavigate();
  const errorHandled = useRef<boolean>(false);

  let errorWithCode: ErrorWithCode | undefined;

  if (!errorHandled.current) {
    if (state.error) {
      showError(
        "Something went wrong connecting to the server. Please try again.",
      );
      errorWithCode = {
        code: "NETWORK_ERROR",
        message: state.error.message,
      };
      errorHandled.current = true;
    } else if (state.data) {
      const typedObjects = extractResponseObjects(state.data);

      for (const typedObject of typedObjects) {
        if (!errorWithCode && isError(typedObject)) {
          errorWithCode = {
            code: typedObject.code,
            message: typedObject.message,
          };
          if (errorWithCode.code === "INVALID_JWT") {
            navigate(`/login?next=${nextParam}`);
          } else if (redirectOnError) {
            navigate(`/error?code=${typedObject.code}&next=${nextParam}`);
          } else if (showSnackbarOnError) {
            let message = getErrorMessage(errorWithCode);
            if (message === null) {
              message = defaultGetErrorMessage(errorWithCode);
            }
            showError(message);
            errorHandled.current = true;
            break; // Exit on first found error
          }
        }
      }
    }
  }

  useEffect(() => {
    errorHandled.current = false;
  }, [state.operation]);

  useEffect(() => {
    if (errorWithCode?.code && onError) {
      onError(errorWithCode);
    }
  }, [onError, errorWithCode?.code]);

  return { error: errorWithCode };
}
