export interface UserFeatureFlags {
  showForgeSession?: boolean;
  showStyleStudioV2?: boolean;
  showHomepageV2?: boolean;
  showInferenceFormV2?: boolean;
  showConnectionIndicator?: boolean;
}

const featureFlagMapping: { [key: string]: keyof UserFeatureFlags } = {
  show_forge_session: "showForgeSession",
  show_style_studio_v2: "showStyleStudioV2",
  show_homepage_v2: "showHomepageV2",
  show_inference_form_v2: "showInferenceFormV2",
  show_connection_indicator: "showConnectionIndicator",
};

export function extractFeatureFlags(featureFlagsList: string[]) {
  const featureFlags: UserFeatureFlags = {};
  featureFlagsList.forEach((flag) => {
    const key = featureFlagMapping[flag];
    if (key) {
      featureFlags[key] = true;
    }
  });
  return featureFlags;
}
