import {
  MenuList as MuiMenuList,
  MenuListProps as MuiMenuListProps,
  styled,
} from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createMixins";
import React from "react";

interface MenuListProps extends MuiMenuListProps {
  size?: "small" | "medium" | "large";
}

const extraProps: PropertyKey[] = ["size"];

const StyledMenuList = styled(MuiMenuList, {
  shouldForwardProp: (prop) => !extraProps.includes(prop),
})<MenuListProps>(({ theme, size }) => {
  const { typography } = theme;

  const styles: CSSProperties = {};

  if (size === "small") {
    Object.assign(styles, {
      fontSize: typography.mini.fontSize,
      lineHeight: typography.mini.lineHeight,
    });
  } else if (size === "large") {
    Object.assign(styles, {
      fontSize: typography.regular.fontSize,
      lineHeight: typography.regular.lineHeight,
    });
  } else {
    Object.assign(styles, {
      fontSize: typography.small.fontSize,
      lineHeight: typography.small.lineHeight,
    });
  }
  return styles;
});

export const MenuList = React.forwardRef<HTMLUListElement, MenuListProps>(
  (props: MenuListProps, ref) => {
    return <StyledMenuList {...props} ref={ref} />;
  },
);
