import { createElement, lazy } from "react";

import {
  Layout,
  WithAccountRequired,
  WithAuthenticationRequired,
} from "../layout";

export default [
  {
    path: "login",
    element: createElement(lazy(() => import("../../routes/login"))),
  },
  {
    path: "logout",
    element: createElement(lazy(() => import("../../routes/logout"))),
  },
  // logged out routes
  {
    element: createElement(Layout),
    children: [
      {
        path: "unverified",
        element: createElement(lazy(() => import("../../routes/unverified"))),
      },
      {
        path: "error",
        element: createElement(lazy(() => import("../../routes/error"))),
      },
    ],
  },
  // logged in routes
  {
    element: createElement(WithAuthenticationRequired),
    children: [
      {
        element: createElement(Layout),
        children: [
          {
            path: "tos",
            element: createElement(lazy(() => import("../../routes/tos"))),
          },
        ],
      },
      // logged in and valid account routes
      {
        element: createElement(WithAccountRequired),
        children: [
          {
            element: createElement(Layout),
            children: [
              {
                index: true,
                element: createElement(
                  lazy(() => import("../../routes/index")),
                ),
              },
              {
                path: "new",
                element: createElement(lazy(() => import("../../routes/new"))),
              },
              {
                path: "settings",
                children: [
                  {
                    element: createElement(
                      lazy(() => import("../../features/users/SettingsLayout")),
                    ),
                    children: [
                      {
                        index: true,
                        element: createElement(
                          lazy(() => import("../../routes/user/settings")),
                        ),
                      },
                      {
                        path: "workspaces",
                        element: createElement(
                          lazy(
                            () =>
                              import(
                                "../../routes/user/settings/UserWorkspaces"
                              ),
                          ),
                        ),
                      },
                      {
                        path: "tokens",
                        element: createElement(
                          lazy(
                            () =>
                              import("../../routes/user/settings/AccessTokens"),
                          ),
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "files",
                children: [
                  {
                    path: ":fileId",
                    element: createElement(
                      lazy(() => import("../../routes/file/index")),
                    ),
                  },
                ],
              },
              {
                path: ":workspace",
                children: [
                  {
                    element: createElement(
                      lazy(() => import("../../routes/workspace/layout")),
                    ),
                    children: [
                      {
                        index: true,
                        element: createElement(
                          lazy(() => import("../../routes/workspace")),
                        ),
                      },
                      {
                        path: "welcome",
                        element: createElement(
                          lazy(() => import("../../routes/workspace/welcome")),
                        ),
                      },
                      {
                        path: "pricing",
                        element: createElement(
                          lazy(() => import("../../routes/workspace/pricing")),
                        ),
                      },
                      {
                        path: "drive/*",
                        element: createElement(
                          lazy(() => import("../../routes/workspace/drive")),
                        ),
                      },
                      {
                        path: "prompts",
                        element: createElement(
                          lazy(
                            () =>
                              import("../../features/workspaces/PromptHistory"),
                          ),
                        ),
                      },
                      {
                        path: "forge",
                        element: createElement(
                          lazy(() => import("../../routes/workspace/forge")),
                        ),
                      },
                      {
                        path: "session",
                        children: [
                          {
                            path: ":sessionId",
                            element: createElement(
                              lazy(
                                () => import("../../routes/workspace/session"),
                              ),
                            ),
                          },
                        ],
                      },
                      {
                        path: "assets",
                        children: [
                          {
                            path: "new",
                            element: createElement(
                              lazy(
                                () => import("../../routes/workspace/newAsset"),
                              ),
                            ),
                          },
                          {
                            path: ":assetId",
                            children: [
                              {
                                index: true,
                                element: createElement(
                                  lazy(
                                    () =>
                                      import("../../routes/workspace/asset"),
                                  ),
                                  { tab: "canvas" },
                                ),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: "styles",
                        children: [
                          {
                            index: true,
                            element: createElement(
                              lazy(
                                () => import("../../features/styles-v2/Styles"),
                              ),
                            ),
                          },
                          {
                            path: "type/:type",
                            element: createElement(
                              lazy(
                                () => import("../../features/styles-v2/Styles"),
                              ),
                            ),
                          },
                          {
                            path: ":slug",
                            children: [
                              {
                                index: true,
                                element: createElement(
                                  lazy(
                                    () => import("../../routes/styles/details"),
                                  ),
                                  { tab: "overview" },
                                ),
                              },
                              {
                                path: "edit",
                                element: createElement(
                                  lazy(
                                    () => import("../../routes/styles/details"),
                                  ),
                                  { tab: "edit" },
                                ),
                              },
                              {
                                path: "variations",
                                element: createElement(
                                  lazy(
                                    () => import("../../routes/styles/details"),
                                  ),
                                  { tab: "variations" },
                                ),
                              },
                              {
                                path: "settings",
                                element: createElement(
                                  lazy(
                                    () => import("../../routes/styles/details"),
                                  ),
                                  { tab: "settings" },
                                ),
                              },
                              {
                                path: "intro",
                                element: createElement(
                                  lazy(
                                    () => import("../../routes/styles/stepper"),
                                  ),
                                  { step: "intro" },
                                ),
                              },
                              {
                                path: "info",
                                element: createElement(
                                  lazy(
                                    () => import("../../routes/styles/stepper"),
                                  ),
                                  { step: "info" },
                                ),
                              },
                              {
                                path: "images",
                                element: createElement(
                                  lazy(
                                    () => import("../../routes/styles/stepper"),
                                  ),
                                  { step: "images" },
                                ),
                              },
                              {
                                path: "prompts",
                                element: createElement(
                                  lazy(
                                    () => import("../../routes/styles/stepper"),
                                  ),
                                  { step: "prompts" },
                                ),
                              },
                              {
                                path: "finalize",
                                element: createElement(
                                  lazy(
                                    () => import("../../routes/styles/stepper"),
                                  ),
                                  { step: "finalize" },
                                ),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: "settings",
                        children: [
                          {
                            element: createElement(
                              lazy(
                                () =>
                                  import(
                                    "../../routes/workspace/settings/layout"
                                  ),
                              ),
                            ),
                            children: [
                              {
                                index: true,
                                element: createElement(
                                  lazy(
                                    () =>
                                      import("../../routes/workspace/settings"),
                                  ),
                                ),
                              },
                              {
                                path: "general",
                                element: createElement(
                                  lazy(
                                    () =>
                                      import(
                                        "../../routes/workspace/settings/general"
                                      ),
                                  ),
                                ),
                              },
                              {
                                path: "members",
                                element: createElement(
                                  lazy(
                                    () =>
                                      import(
                                        "../../routes/workspace/settings/members"
                                      ),
                                  ),
                                ),
                              },
                              {
                                path: "plans",
                                element: createElement(
                                  lazy(
                                    () =>
                                      import(
                                        "../../routes/workspace/settings/plans"
                                      ),
                                  ),
                                ),
                              },
                              {
                                path: "billing",
                                element: createElement(
                                  lazy(
                                    () =>
                                      import(
                                        "../../routes/workspace/settings/billing"
                                      ),
                                  ),
                                ),
                              },
                              {
                                path: "notices",
                                element: createElement(
                                  lazy(
                                    () =>
                                      import(
                                        "../../routes/workspace/settings/notices"
                                      ),
                                  ),
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: createElement(Layout),
    children: [
      {
        path: "*",
        element: createElement(
          lazy(() => import("../../components/errors/Error404")),
        ),
      },
    ],
  },
]; // todo: TypeScript 4.9 `satisfies RouteObject[]`
