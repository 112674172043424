import { useIntercom } from "react-use-intercom";

import { WorkspaceDropdown } from "../../../../../../features/nav-v2/components";
import { useBasicData } from "../../../../../../hooks";
import { getConnectionNodesTyped } from "../../../../../../utils/relay";

export default function WorkspaceSelectionButton() {
  const { user, workspace } = useBasicData();
  const memberships = getConnectionNodesTyped(user?.memberships);
  const workspaces = memberships.map((membership) => membership.workspace);
  const { showNewMessage } = useIntercom();

  if (!workspace || !workspace.myRole || !workspaces) {
    return null;
  }

  return (
    <WorkspaceDropdown
      allWorkspaces={workspaces}
      currentWorkspaceId={workspace.id}
      personalWorkspaceId={user?.personalWorkspace?.id}
      userEmail={user?.email}
      userFullName={user?.name}
      userProfilePictureUrl={user?.profilePicture}
      handleIntercomMessage={showNewMessage}
    />
  );
}
