interface ChevronIconProps {}

export function ChevronIcon(props: ChevronIconProps) {
  return (
    <svg
      viewBox="-2 -2 12 12"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "1em", height: "1em" }}
      {...props}
    >
      <path
        fill="currentColor"
        d="M 4 6.2854 L 0 2.8568 L 0.9791 1.7146 L 4 4.3039 L 7.0209 1.7146 L 8 2.8568 L 4 6.2854 Z"
      />
    </svg>
  );
}
