import { useParams } from "react-router-dom";
import { useQuery } from "urql";

import { extractFeatureFlags } from "../features/users/featureFlags";
import { isError } from "../utils/graphql";
import { getConnectionNodes } from "../utils/relay";
import {
  BasicFileFragmentFragment,
  BasicUserFragmentFragment,
  GetBasicData,
  GetBasicDataWorkspaceFragmentFragment,
} from "./queries.graphql";

type UseBasicDataProps = {
  fetch?: boolean;
};

export function isUserFragment(obj: any): obj is BasicUserFragmentFragment {
  return obj?.__typename === "User";
}

export function isWorkspaceFragment(
  obj: any,
): obj is GetBasicDataWorkspaceFragmentFragment {
  return obj?.__typename === "Workspace";
}

function isFileFragment(obj: any): obj is BasicFileFragmentFragment {
  return obj?.__typename === "File";
}

export default function useBasicData(props?: UseBasicDataProps) {
  const { workspace: workspaceName, fileId } = useParams();

  const hasWorkspaceName = !!workspaceName && workspaceName !== "me";
  const [query, refreshBasicData] = useQuery({
    query: GetBasicData,
    variables: {
      hasWorkspaceName,
      workspaceName: workspaceName ?? "",
      hasFileId: !!fileId,
      fileId: fileId ?? "",
    },
    requestPolicy: props?.fetch ? "network-only" : "cache-only",
  });

  const respMe = query.data?.me;
  const respWorkspace = query.data?.workspace;
  const respFile = query.data?.file;
  const user = isUserFragment(respMe) ? respMe : null;
  const userError = isError(respMe) ? respMe : null;
  const workspace = isWorkspaceFragment(respWorkspace) ? respWorkspace : null;
  const workspaceError = isError(respWorkspace) ? respWorkspace : null;
  const file = isFileFragment(respFile) ? respFile : null;
  const fileError = isError(respFile) ? respFile : null;
  const featureFlags = user ? extractFeatureFlags(user.featureFlags) : {};

  return {
    fetching: query.fetching,
    error: query.error,
    user,
    userError,
    workspace: workspace || file?.workspace || null,
    workspaceError,
    file,
    fileError,
    featureFlags,
    refreshBasicData,
    pinnedStyles: getConnectionNodes(workspace?.pinnedStyles),
  };
}
